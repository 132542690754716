import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { ProjectSelection } from "@/models";
import { useProject } from "@/context/ProjectContext";
import { useDebounce } from "usehooks-ts";

interface SelectionsResponse {
  data: ProjectSelection[];
  subtotal: number;
  count: number;
}

export function useProjectSelections() {
  const project = useProject();
  return useQuery(["projectSelections", project.id], () =>
    axios
      .get<SelectionsResponse>(`projects/${project.id}/selections`)
      .then(({ data }) => data),
  );
}

export function useCreateProjectSelection() {
  const queryClient = useQueryClient();
  const project = useProject();

  return useMutation<
    SelectionsResponse,
    AxiosError,
    { option_id: number; quantities: number[] }
  >(
    (data) =>
      axios
        .post<SelectionsResponse>(`projects/${project.id}/selections`, data)
        .then(({ data }) => {
          queryClient.setQueryData<SelectionsResponse>(
            ["projectSelections", project.id],
            data,
          );
          return data;
        }),
    {
      onError: () => null,
    },
  );
}

export function useDeleteProjectSelection() {
  const queryClient = useQueryClient();
  const project = useProject();

  return useMutation((selectionId: number) =>
    axios
      .delete<SelectionsResponse>(
        `projects/${project.id}/selections/${selectionId}`,
      )
      .then(({ data }) => {
        queryClient.setQueryData<SelectionsResponse>(
          ["projectSelections", project.id],
          data,
        );
      }),
  );
}

export function usePreviewProjectSelection(payload: {
  option_id: number;
  quantities: number[];
}) {
  const project = useProject();
  const debounced = useDebounce(payload, 300);

  const query = useQuery(
    ["previewSelection", JSON.stringify(debounced)],
    () =>
      axios
        .post<ProjectSelection>(
          `projects/${project.id}/selections/preview`,
          debounced,
        )
        .then(({ data }) => data)
        .catch(() => null),
    {
      enabled: payload.quantities.some((q) => q > 0),
    },
  );

  return {
    ...query,
    isLoading: query.isLoading && query.fetchStatus !== "idle",
  };
}
