import { Proof } from "@/models";
import ProofImage from "@/components/proofs/ProofImage";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export default function ProofsCard({ proofs }: { proofs: Proof[] }) {
  if (proofs.length === 0) {
    return null;
  }
  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg">Proofs</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
          {proofs.map((proof) => (
            <ProofImage
              className="overflow-hidden rounded"
              key={proof.id}
              proof={proof}
            />
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
