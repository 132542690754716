import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button, ButtonProps } from "@/components/ui/button";
import startCase from "lodash/startCase";
import { MembershipRole, Project } from "@/models";
import { useUpdateProjectStatus } from "@/api/project";
import Gate from "@/components/roles/Gate";
import { ReactNode, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Alert } from "@/components/ui/alert";
import { LucideIcon } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";

export interface ProjectActionProps {
  status: Project["status"];
  label: string;
  icon?: LucideIcon;
  role?: MembershipRole;
  description?: ReactNode;
  direction: "prev" | "next";
  disabled?: boolean;
  children?: ReactNode;
  withNote?: boolean;
  buttonVariant?: ButtonProps["variant"];
}

export default function ProjectAction({
  status,
  label,
  role,
  icon: Icon,
  description,
  direction,
  disabled,
  children,
  withNote,
  buttonVariant = "default",
}: ProjectActionProps) {
  const [open, setOpen] = useState(false);
  const noteRef = useRef<HTMLTextAreaElement | null>(null);
  const updateStatus = useUpdateProjectStatus();

  const handleAction = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const note = noteRef.current?.value || null;
    updateStatus.mutateAsync({ status, note }).then(() => {
      setOpen(false);
    });
  };

  useEffect(() => {
    updateStatus.reset();
  }, [open]);

  return (
    <Gate role={role ?? []}>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogTrigger asChild>
          {children || (
            <Button
              variant={direction === "next" ? "default" : "outline"}
              disabled={disabled}
            >
              {label}
              {Icon && <Icon className="-mr-1 ml-2 size-5" />}
            </Button>
          )}
        </AlertDialogTrigger>
        <AlertDialogContent>
          <form onSubmit={handleAction} className="grid gap-4">
            <AlertDialogHeader>
              <AlertDialogTitle>{label}</AlertDialogTitle>
            </AlertDialogHeader>
            <p className="text-sm">
              {description ||
                `Are you sure you want to ${
                  direction === "prev" ? "go back" : "proceed"
                } to ${startCase(status)}?`}
            </p>

            {updateStatus.error && (
              <Alert variant="destructive">
                {updateStatus.error.response?.data?.message ??
                  "An error occurred."}
              </Alert>
            )}

            {withNote && (
              <Textarea ref={noteRef} placeholder="Add a note" required />
            )}

            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <Button
                type="submit"
                variant={buttonVariant}
                isLoading={updateStatus.isLoading}
              >
                {label}
              </Button>
            </AlertDialogFooter>
          </form>
        </AlertDialogContent>
      </AlertDialog>
    </Gate>
  );
}
