import { Checkbox } from "@/components/ui/checkbox";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { UseFormReturn } from "react-hook-form";
import AddressFields from "@/components/addresses/AddressFields";
import AddressSelectList from "@/components/addresses/AddressSelectList";
import { Address, AddressPayload } from "@/models";
import { useState } from "react";
import { Label } from "@/components/ui/label";
import { Card } from "@/components/ui/card";
import { DEFAULT_ADDRESS } from "@/components/addresses/AddressNewDialog";

export default function AddressFormSection({
  form,
  label = "Choose an address",
  size,
}: {
  form: UseFormReturn<
    {
      address: AddressPayload;
    } & any
  >;
  label?: string;
  size?: "sm" | "md" | "lg";
}) {
  const [addressId, setAddressId] = useState<number | "new">("new");

  const handleAddressSelect = (address: Address | "new") => {
    if (address === "new") {
      setAddressId("new");
      form.setValue("address", DEFAULT_ADDRESS);
    } else {
      setAddressId(address.id);
      form.setValue("address", address);
    }
  };

  return (
    <div>
      <div className="space-y-2">
        <Label>{label}</Label>
        <AddressSelectList
          value={addressId}
          onChange={handleAddressSelect}
          size={size}
        />
      </div>

      {addressId === "new" && (
        <Card className="mt-2 space-y-3 p-5">
          <AddressFields form={form} />

          <FormField
            control={form.control}
            name="address.save_address"
            render={({ field }) => (
              <FormItem className="flex items-center space-x-3 space-y-0 whitespace-nowrap">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormLabel>Save to Address Book</FormLabel>
              </FormItem>
            )}
          />
        </Card>
      )}
    </div>
  );
}
