import { Order } from "@/models";
import { Button } from "@/components/ui/button";
import { curr } from "@/lib/curr";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ChevronDown, FileText } from "lucide-react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { useChargeRequest, usePayRequest } from "@/lib/orders";
import { SyntheticEvent, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useSettings } from "@/context/AppContext";
import { PaymentMethod } from "@stripe/stripe-js";
import { Alert } from "@/components/ui/alert";

export default function InvoiceActions({ invoice }: { invoice: Order }) {
  const [amount, setAmount] = useState<string>(String(invoice.balance));
  const payRequest = usePayRequest();
  const chargeRequest = useChargeRequest();
  const [isMakingPartialPayment, setIsMakingPartialPayment] = useState(false);
  const [payingWithBank, setPayingWithBank] = useState<PaymentMethod>();
  const { bank_accounts } = useSettings();

  const downloadRequest = useMutation(() =>
    axios
      .get<{ url: string }>(`/orders/${invoice.code}?format=pdf`)
      .then(({ data }) => {
        const el = document.createElement("a");
        el.setAttribute("href", data.url);
        el.setAttribute("target", "_blank");
        el.setAttribute("download", `invoice-${invoice.id}.pdf`);
        el.click();
      }),
  );

  const handlePartialPayment = (e: SyntheticEvent) => {
    e.preventDefault();
    payRequest.mutate({
      invoices: [invoice.code],
      amount: Number(amount),
    });
  };

  return (
    <>
      {invoice.balance > 0 && (
        <div className="flex">
          <Button
            onClick={() => payRequest.mutate({ invoices: [invoice.code] })}
            isLoading={payRequest.isLoading}
            className="rounded-r-none"
          >
            Pay {curr(invoice.balance)}
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button className="rounded-l-none border-l border-l-black/25 px-3">
                <ChevronDown className="size-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onClick={() => setIsMakingPartialPayment(true)}>
                Make Partial Payment
              </DropdownMenuItem>
              {bank_accounts.map((account) => (
                <DropdownMenuItem
                  key={account.id}
                  onClick={() => setPayingWithBank(account)}
                >
                  Pay with {account.us_bank_account!.bank_name} ****
                  {account.us_bank_account!.last4}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>

          <Dialog
            open={isMakingPartialPayment}
            onOpenChange={setIsMakingPartialPayment}
          >
            <DialogContent className="sm:max-w-[425px]">
              <form onSubmit={handlePartialPayment} className="space-y-4">
                <DialogHeader>
                  <DialogTitle>Make Partial Payment</DialogTitle>
                </DialogHeader>

                <div>
                  <Label htmlFor="name" className="mb-2 block">
                    How much?
                  </Label>
                  <Input
                    type="number"
                    max={invoice.balance}
                    min={1}
                    step={0.01}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>

                <DialogFooter>
                  <Button
                    type="submit"
                    disabled={!amount}
                    isLoading={payRequest.isLoading}
                  >
                    Submit
                  </Button>
                </DialogFooter>
              </form>
            </DialogContent>
          </Dialog>

          <Dialog
            open={payingWithBank != null}
            onOpenChange={() => setPayingWithBank(undefined)}
          >
            {payingWithBank && (
              <DialogContent className="sm:max-w-[425px]">
                <DialogTitle>
                  Pay with {payingWithBank.us_bank_account!.bank_name} ****
                  {payingWithBank.us_bank_account!.last4}
                </DialogTitle>

                {chargeRequest.data ? (
                  <Alert variant="success" className="my-2">
                    {chargeRequest.data.async
                      ? "We have initiated a debit from your bank account. Funds will be debited from your account the next business day, and will settle within 4 business days.  In the meantime, this order will show as not paid in our system.  In order to avoid duplicate charges, please do not initiate another payment for this order."
                      : "We have successfully received your payment."}
                  </Alert>
                ) : (
                  <div>
                    Are you sure you want to debit your bank account for{" "}
                    {curr(invoice.balance)}? The payment will take up to 4
                    business days to fully process.
                  </div>
                )}

                <DialogFooter>
                  <Button
                    variant="outline"
                    onClick={() => setPayingWithBank(undefined)}
                  >
                    {chargeRequest.data ? "OK" : "Cancel"}
                  </Button>
                  {!chargeRequest.data && (
                    <Button
                      isLoading={chargeRequest.isLoading}
                      onClick={() =>
                        chargeRequest.mutate({
                          payment_method: payingWithBank.id,
                          invoices: [invoice.id],
                        })
                      }
                    >
                      Yes
                    </Button>
                  )}
                </DialogFooter>
              </DialogContent>
            )}
          </Dialog>
        </div>
      )}
      <Button
        variant="outline"
        onClick={() => downloadRequest.mutate()}
        isLoading={downloadRequest.isLoading}
      >
        <FileText className="mr-2 size-4" /> Download
      </Button>
    </>
  );
}
