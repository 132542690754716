import { Product } from "@/models";
import { imageUrl } from "@/lib/image";
import { curr } from "@/lib/curr";
import { Skeleton } from "@/components/ui/skeleton";
import { Card } from "@/components/ui/card";
import AddProductIcon from "@/components/products/AddProductIcon";
import pluralize from "pluralize";

export default function ProductCard({ product }: { product: Product }) {
  return (
    <Card className="relative pt-4 text-xs transition-all hover:shadow-md sm:text-sm">
      <div className="mb-24">
        <img
          className="mx-auto h-40 w-full object-contain md:h-72"
          src={imageUrl(product.image, { w: 500 })}
          alt={product.name}
        />
      </div>
      <div className="absolute bottom-0 w-full bg-white px-3 py-4 md:px-4">
        <div className="mb-2 line-clamp-3 font-semibold leading-tight text-black">
          {product.name}
        </div>
        <div className="text-xs text-slate-700">
          As low as <b className="font-medium">{curr(product.min_price)} ea</b>
        </div>
        <div className="mt-0.5 text-xs text-slate-700">
          {product.min_qty
            ? `Min Qty: ${product.min_qty}`
            : `${pluralize("size", product.variants.length, true)}`}
        </div>
      </div>
      <AddProductIcon />
    </Card>
  );
}

export function ProductCardSkeleton() {
  return (
    <Card className="relative">
      <div className="mb-32">
        <Skeleton className="h-72 w-full" />
      </div>
      <div className="absolute bottom-0 w-full bg-white px-5 pb-4 pt-2 text-center">
        <Skeleton className="mb-4 h-6" />
        <Skeleton className="mb-2 h-3" />
        <Skeleton className="mb-2 h-3" />
      </div>
    </Card>
  );
}
