import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import startCase from "lodash/startCase";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { PlusIcon, X } from "lucide-react";
import { DecorationMethod } from "@/constants/project";
import DecorationFields from "@/components/products/DecorationFields";
import { LayoutConfig, Product, ZodLayoutConfig } from "@/models";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import DesignLayoutSelect from "@/components/selects/DesignLayoutSelect";

export default function LayoutBuilderDialog({
  type,
  product,
  initialValue,
  onSubmit,
}: {
  type: DecorationMethod;
  product?: Product;
  initialValue: LayoutConfig;
  onSubmit: (l: LayoutConfig) => void;
}) {
  const [open, setOpen] = useState(false);
  const [isExistingLayout, setExistingLayout] = useState(
    initialValue.id != null,
  );

  const form = useForm<LayoutConfig>({
    resolver: zodResolver(ZodLayoutConfig),
    defaultValues: initialValue,
  });

  const locationsField = useFieldArray({
    control: form.control,
    name: "locations",
  });

  useEffect(() => {
    if (open) {
      form.reset(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (!isExistingLayout) {
      form.setValue("id", null);
    }
  }, [form, isExistingLayout]);

  const handleSubmit = (values: LayoutConfig) => {
    onSubmit(
      isExistingLayout
        ? {
            id: values.id,
            name: null,
            locations: [],
          }
        : {
            id: null,
            name: values.name,
            locations: values.locations,
          },
    );
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Configure {startCase(type)} Layout</Button>
      </DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <form
            onSubmit={(e) => {
              e.stopPropagation();
              return form.handleSubmit(handleSubmit)(e);
            }}
          >
            <DialogHeader>
              <DialogTitle>Configure {startCase(type)} Layout</DialogTitle>
            </DialogHeader>

            <div className="w-full space-y-3 py-5">
              <div className="mb-4 flex items-center space-x-2">
                <Switch
                  id="existing-layout-switch"
                  checked={isExistingLayout}
                  onCheckedChange={setExistingLayout}
                />
                <Label htmlFor="existing-layout-switch">
                  Use Existing Design Layout
                </Label>
              </div>

              {isExistingLayout ? (
                <FormField
                  control={form.control}
                  name="id"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Existing Layout</FormLabel>
                      <DesignLayoutSelect
                        value={field.value}
                        onChange={field.onChange}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ) : (
                <>
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Layout Name</FormLabel>
                        <FormControl>
                          <Input {...field} value={field.value || ""} />
                        </FormControl>
                        <FormMessage />
                        <FormDescription>
                          Options with the same name will be grouped in the same
                          order.
                        </FormDescription>
                      </FormItem>
                    )}
                  />

                  {type === "ad_specialty" && product ? (
                    <DecorationFields
                      valuesKey="locations.0"
                      product={product}
                    />
                  ) : (
                    <>
                      {locationsField.fields.map((f, i) => (
                        <div key={f.id} className="flex w-full items-end gap-2">
                          <FormField
                            control={form.control}
                            name={`locations.${i}.location`}
                            render={({ field }) => (
                              <FormItem className="flex-grow">
                                <FormLabel>Imprint Location</FormLabel>
                                <FormControl>
                                  <Input {...field} value={field.value || ""} />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name={`locations.${i}.complexity`}
                            render={({ field }) => (
                              <FormItem className="flex-grow">
                                <FormLabel>
                                  {type === "screenprint"
                                    ? "# Colors"
                                    : type === "embroidery"
                                    ? "# Stitches"
                                    : "Complexity"}
                                </FormLabel>
                                <FormControl>
                                  <Input
                                    {...field}
                                    value={
                                      field.value == null ? "" : field.value
                                    }
                                    type="number"
                                    step={1}
                                    min={1}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <button
                            type="button"
                            onClick={() => locationsField.remove(i)}
                            className="inline-flex size-10 items-center justify-center"
                          >
                            <X className="size-4 text-muted-foreground" />
                          </button>
                        </div>
                      ))}
                      <Button
                        type="button"
                        variant="ghost"
                        onClick={() =>
                          locationsField.append({
                            location: "",
                            complexity: 1,
                          })
                        }
                      >
                        Add Location <PlusIcon className="ml-1.5 size-4" />
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>

            <DialogFooter>
              <Button variant="outline" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
