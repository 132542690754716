import ProjectActivityItem from "@/components/projects/activity/ProjectActivityItem";
import ProjectMessageTextarea from "@/components/projects/ProjectMessageTextarea";
import ProjectUsers from "@/components/projects/ProjectUsers";
import {
  useGetProjectActivity,
  useWatchForNewActivity,
} from "@/api/project-activity";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export default function ProjectActivitySection() {
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useGetProjectActivity();
  useWatchForNewActivity();

  return (
    <Card className="overflow-visible">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle className="text-xl">Activity</CardTitle>
        <ProjectUsers />
      </CardHeader>

      <CardContent className="pl-8 text-sm">
        {hasNextPage && (
          <button
            className="mb-4 text-xs font-medium"
            disabled={isFetchingNextPage}
            onClick={() => fetchNextPage()}
          >
            {isFetchingNextPage ? "Loading..." : "Load Older"}
          </button>
        )}
        {data && (
          <ol className="relative mb-4 flex w-full flex-col-reverse gap-y-4 border-l border-slate-200 dark:border-slate-700">
            {data.pages
              .flatMap((p) => p.data)
              .map((a) => (
                <ProjectActivityItem key={a.id} activity={a} />
              ))}
          </ol>
        )}
        <ProjectMessageTextarea />
      </CardContent>
    </Card>
  );
}
