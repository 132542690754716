import { Collection, Product, ProductDecoration } from "@/models";

export function collectionHasProducts(collection: Collection) {
  if (collection.products_count !== undefined) {
    return collection.products_count > 0;
  }
  if (collection.products) {
    return collection.products.length > 0;
  }
  // We don't know if it has products, so we assume it does
  return true;
}

export function findProductDecoration(
  product?: Product,
  decorationConfig?: { location?: string | null; decoration?: string | null },
): ProductDecoration | undefined {
  const productDecorations = product?.decorations || [];
  const decoration = decorationConfig || {};

  return productDecorations.find(
    (d) =>
      d.location === decoration.location &&
      d.decoration === decoration.decoration,
  );
}
