import { createContext, useContext } from "react";
import { Business } from "@/models";

const BusinessContext = createContext<{
  business: Business | null;
  setBusiness?: (business: Business | null) => void;
}>({
  business: null,
});

export function useBusiness() {
  return useContext(BusinessContext).business;
}

export function useSetBusiness() {
  return useContext(BusinessContext).setBusiness || (() => null);
}

export default BusinessContext;
