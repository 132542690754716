import { z } from "zod";
import Title from "@/components/ui/title";
import Description from "@/components/ui/description";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { DeepPartial, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Textarea } from "@/components/ui/textarea";
import Attachments from "@/components/attachments/Attachments";
import ProjectItemRow from "@/components/projects/create/ProjectItemRow";
import Toolbar from "@/components/ui/toolbar";
import WizardStep from "@/components/wizard/WizardStep";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";
import { ProjectType } from "@/models";
import BrandReminder from "@/components/brand/BrandReminder";

const formSchema = z.object({
  description: z.string().min(2),
  attachments: z
    .array(z.object({ url: z.string(), file: z.string(), name: z.string() }))
    .optional(),
  items: z.array(
    z.object({
      product_id: z.number().nullish(),
      collection_id: z.number(),
      variants: z
        .array(z.object({ variant_id: z.number(), qty: z.number() }))
        .optional(),
      decoration: z.any(),
      notes: z.string(),
    }),
  ),
});

type FormShape = z.infer<typeof formSchema>;

export default function ProjectReview({
  defaultValues,
  onSubmit,
  projectType = "project",
}: {
  defaultValues: DeepPartial<FormShape>;
  onSubmit: (v: FormShape) => Promise<void>;
  projectType?: ProjectType;
}) {
  const form = useForm<FormShape>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const items = form.watch("items");

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <WizardStep>
          <Toolbar>
            <Title>Let's review your {projectType}</Title>
            <Description>
              Give our team the details so we can provide you the best options.
            </Description>
          </Toolbar>

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    placeholder={`A description of your ${projectType} and any clarifying details.`}
                    autoFocus
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="attachments"
            render={({ field }) => {
              const attachments = field.value || [];
              return (
                <FormItem>
                  <FormLabel className="flex items-center gap-2">
                    Attachments <BrandReminder />
                  </FormLabel>
                  <FormControl>
                    <Attachments
                      className="rounded-md border bg-card px-3 py-2"
                      attachments={field.value || []}
                      onNew={(files) =>
                        field.onChange([...attachments, ...files])
                      }
                      onDelete={(file) =>
                        field.onChange(
                          attachments.filter((a) => a.file !== file.file),
                        )
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="items"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Items</FormLabel>

                <ul className="space-y-3">
                  {field.value.map((item, index) => (
                    <ProjectItemRow
                      key={index}
                      item={item}
                      setItem={(item) =>
                        field.onChange(
                          field.value.map((r, i) => {
                            return i === index ? item : r;
                          }),
                        )
                      }
                    />
                  ))}
                </ul>

                <FormMessage />
              </FormItem>
            )}
          />

          <WizardSubmitButton isLoading={form.formState.isSubmitting}>
            {items.some((i) => i.product_id)
              ? "Request Proofs"
              : "Request Options"}
          </WizardSubmitButton>
        </WizardStep>
      </form>
    </Form>
  );
}
