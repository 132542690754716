import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Product } from "@/models";

export function useSearchProducts({
  query,
  filters = {},
  page = 0,
  all = false,
}: {
  query: string;
  filters?: { [key: string]: string | undefined };
  page?: number;
  all?: boolean;
}) {
  const queryClient = useQueryClient();
  return useQuery(
    ["searchProducts", query, JSON.stringify(filters), page],
    () =>
      axios
        .get<{
          data: Product[];
          meta: { current_page: number; last_page: number };
          facets: Record<string, Record<string, number>>;
        }>("/products", { params: { query, filters, page: page + 1, all } })
        .then(({ data }) => {
          data.data.forEach((product) => {
            queryClient.setQueryData(["productById", product.id], product);
          });
          return data;
        }),
  );
}

export function useGetProduct(slug: string) {
  return useQuery(["product", slug], () =>
    axios
      .get<{ data: Product; colors: Product[] }>(`/products/${slug}`)
      .then(({ data }) => data),
  );
}

export function useGetProductById(id?: number | null) {
  return useQuery(
    ["productById", id],
    () => axios.get<Product>(`/products/by-id/${id}`).then(({ data }) => data),
    { staleTime: Infinity, refetchOnMount: false, enabled: id != null },
  );
}
