import { useParams } from "react-router-dom";
import SplashScreen from "@/pages/SplashScreen";
import Title from "@/components/ui/title";
import Description from "@/components/ui/description";
import { DataCell, DataList } from "@/components/ui/data-list";
import { intlFormat } from "date-fns";
import AddressBlock from "@/components/addresses/AddressBlock";
import FulfillmentOrderStatus from "@/components/fulfillmentOrders/FulfillmentOrderStatus";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Card } from "@/components/ui/card";
import { useGetFulfillmentOrder } from "@/api/fulfillment-order";
import { useTitle } from "@/lib/title";

export default function FulfillmentOrderDetail() {
  const { id } = useParams<{ id: string }>();

  const { data: order, isLoading } = useGetFulfillmentOrder(Number(id));
  useTitle(order?.number, "Fulfillment Orders");

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!order) {
    return null;
  }

  return (
    <div className="flex-1 space-y-8">
      <div>
        <Title className="flex items-center gap-4">
          {order.number}
          <FulfillmentOrderStatus status={order.status} />
        </Title>
        <Description>
          <span>Inventory order placed </span>
          {order.user && <span>by {order.user.name} </span>}
          <span>on {intlFormat(new Date(order.date))}</span>
        </Description>
      </div>

      <Card className="p-5">
        <DataList className="xl:grid-cols-3">
          <DataCell label="Shipping Address">
            <AddressBlock address={order.address} />
          </DataCell>
          <DataCell label="Contact Information">
            <div>{order.name}</div>
            <div>{order.email}</div>
            <div>{order.phone}</div>
          </DataCell>
          {order.shipments.map((s) => (
            <DataCell label="Tracking Number" key={s.id}>
              <a
                target="_blank"
                rel="noreferrer"
                href={s.tracking_url || "#"}
                className="font-medium text-primary"
              >
                {s.tracking_number}
              </a>
            </DataCell>
          ))}
        </DataList>
      </Card>

      <Card>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>SKU</TableHead>
              <TableHead>Description</TableHead>
              <TableHead>Quantity</TableHead>
              <TableHead>Notes</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {order.items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <span className="font-medium">{item.sku}</span>
                </TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.qty.toLocaleString()}</TableCell>
                <TableCell>{item.notes}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}
