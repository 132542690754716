import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import axios from "axios";
import { handleLaravelErrors, maybeRenderRootError } from "@/lib/form";
import { Alert } from "@/components/ui/alert";
import { FulfillmentOrder, ZodAddress } from "@/models";
import { useNavigate } from "react-router-dom";
import SkuItemById from "@/components/inventory/SkuItemById";
import AddFulfillmentOrderItemForm from "@/components/fulfillmentOrders/AddFulfillmentOrderItemForm";
import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { CalendarIcon, X } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import AddressFormSection from "@/components/addresses/AddressFormSection";
import { useAppState } from "@/context/AppContext";
import startCase from "lodash/startCase";
import sending from "@/assets/lottie/sending.json";
import Lottie from "lottie-react";
import { useRef, useState } from "react";

const formSchema = z.object({
  email: z.string().email(),
  address: ZodAddress,
  earliest_ship_date: z.date().optional(),
  items: z
    .array(
      z.object({
        variant_id: z.number(),
        qty: z.number(),
      }),
    )
    .min(1),
  customer_po: z.string().optional(),
  send_confirmation: z.boolean().default(true),
});

const METHODS = [
  "Best Value",
  "Cheapest",
  "Ground",
  "2-Day",
  "3-Day",
  "Next Day Standard (PM)",
  "Next Day AM",
  "Next Day Air",
  "Pickup",
];

export default function FulfillmentOrderForm() {
  const navigate = useNavigate();
  const [isSending, setIsSending] = useState(false);
  const createdOrderRef = useRef<FulfillmentOrder>();

  const { settings } = useAppState();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      address: {
        method: "Best Value",
        country: "US",
      },
      items: [],
      send_confirmation: true,
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    setIsSending(true);
    if (values.address.method?.startsWith("third_party:")) {
      Object.assign(values.address, {
        method: "Third Party",
        third_party_account: values.address.method.split(":")[1],
      });
    }

    return axios
      .post<FulfillmentOrder>(`/fulfillment-orders`, values)
      .then(({ data }) => {
        createdOrderRef.current = data;

        if (!isSending) {
          onSendingComplete();
        }
      })
      .catch((e) => {
        handleLaravelErrors(form)(e);
        setIsSending(false);
      });
  }

  function onAddItem(variantId: number, qty: number) {
    form.setValue("items", [
      ...form.getValues().items,
      { variant_id: variantId, qty: qty },
    ]);
  }

  function removeItemAtIndex(index: number) {
    form.setValue(
      "items",
      form.getValues().items.filter((_, i) => i !== index),
    );
  }

  function onSendingComplete() {
    setIsSending(false);
    if (createdOrderRef.current) {
      navigate(`/fulfillment-orders/${createdOrderRef.current.id}?created=1`);
    }
  }

  const items = form.watch("items");

  return (
    <Form {...form}>
      {maybeRenderRootError(form)}

      {form.formState.isSubmitSuccessful && (
        <Alert variant="success">Fulfillment order created successfully.</Alert>
      )}

      {isSending && (
        <div className="pointer-events-none absolute inset-0 bg-white/75">
          <Lottie
            animationData={sending}
            className="h-full w-full"
            onLoopComplete={onSendingComplete}
          />
        </div>
      )}

      <div className="grid gap-5 lg:grid-cols-2">
        <Card className="p-6">
          <AddFulfillmentOrderItemForm onSubmit={onAddItem} />

          <h5 className="mb-4 mt-6 font-medium">Items in Order</h5>
          <ul className="space-y-4">
            {items.map((item, index) => (
              <li key={`${item.variant_id}.${index}`}>
                <SkuItemById variantId={item.variant_id} qty={item.qty}>
                  <button
                    type="button"
                    onClick={() => removeItemAtIndex(index)}
                  >
                    <X className="size-4 text-slate-500" />
                  </button>
                </SkuItemById>
              </li>
            ))}
          </ul>
          {items.length === 0 && (
            <p className="text-muted-foreground">No items in order.</p>
          )}
        </Card>

        <Card className="p-6">
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
            <AddressFormSection form={form} size="sm" />

            <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
              <div className="lg:col-span-2">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input type="email" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="send_confirmation"
                  render={({ field }) => (
                    <FormItem className="mt-3 flex items-center space-x-3 space-y-0 whitespace-nowrap">
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel>Send Shipment Confirmation</FormLabel>
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name="address.method"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Method</FormLabel>
                    <Select
                      value={field.value || ""}
                      onValueChange={field.onChange}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a method" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent className="max-h-[20rem] overflow-y-auto">
                        {METHODS.map((method) => (
                          <SelectItem key={method} value={method}>
                            {method}
                          </SelectItem>
                        ))}
                        {settings.third_party_accounts.map((account) => (
                          <SelectItem
                            key={account.account_number}
                            value={`third_party:${account.account_number}`}
                          >
                            {startCase(account.carrier)} Third Party #
                            {account.account_number}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="earliest_ship_date"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Earliest Ship Date</FormLabel>
                    <FormControl>
                      <div>
                        <Popover>
                          <PopoverTrigger asChild>
                            <Button
                              variant={"outline"}
                              className={cn(
                                "w-full justify-start rounded-lg text-left font-normal",
                                !field.value && "text-muted-foreground",
                              )}
                            >
                              <CalendarIcon className="mr-2 size-4" />
                              {field.value ? (
                                format(field.value, "PPP")
                              ) : (
                                <span>Pick a date</span>
                              )}
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0">
                            <Calendar
                              mode="single"
                              selected={field.value}
                              onSelect={field.onChange}
                              fromDate={new Date()}
                              initialFocus
                            />
                          </PopoverContent>
                        </Popover>
                      </div>
                    </FormControl>
                    <FormDescription className="text-xs">
                      If specified, we won't ship the order until this date.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="customer_po"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Internal Reference</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} />
                    </FormControl>
                    <FormDescription className="text-xs">
                      This optional field could be a PO number, cost center, or
                      any reference number.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="mt-8 flex justify-end">
              <Button type="submit" isLoading={form.formState.isSubmitting}>
                Submit
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </Form>
  );
}
