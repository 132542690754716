import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Address, AddressPayload } from "@/models";

export function useGetAddresses() {
  return useQuery(["addresses"], () =>
    axios
      .get<{
        data: Address[];
      }>("/addresses")
      .then(({ data }) => data.data),
  );
}

export function useGetAddress(addressId: number) {
  return useQuery(["address", addressId], () =>
    axios.get<Address>(`/addresses/${addressId}`).then(({ data }) => data),
  );
}

export function useUpdateAddress(addressId: number) {
  const queryClient = useQueryClient();

  return useMutation((address: AddressPayload) => {
    return axios
      .put<Address>(`/addresses/${addressId}`, address)
      .then(({ data }) => {
        queryClient.setQueryData<Address[]>(["addresses"], (prev) => {
          if (!prev) {
            return prev;
          }
          return prev.map((address) =>
            addressId === data.id ? data : address,
          );
        });
        return data;
      });
  });
}

export function useDeleteAddress() {
  const queryClient = useQueryClient();

  return useMutation((addressId: number) => {
    return axios.delete(`/addresses/${addressId}`).then(({ data }) => {
      queryClient.setQueryData<Address[]>(["addresses"], (prev) => {
        if (!prev) {
          return prev;
        }
        return prev.filter((p) => p.id !== addressId);
      });
      return data;
    });
  });
}

export function useAddAddress() {
  const queryClient = useQueryClient();

  return useMutation((address: AddressPayload) => {
    return axios.post<Address>(`/addresses`, address).then(({ data }) => {
      queryClient.setQueryData<Address[]>(["addresses"], (prev) => {
        if (!prev) {
          return prev;
        }
        return [data, ...prev];
      });
      return data;
    });
  });
}
