import { OrderPreviewModel } from "@/models";
import {
  Table,
  TableBody,
  TableBodySkeleton,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { curr } from "@/lib/curr";
import { Card } from "@/components/ui/card";

export default function OrderPreviewTable({
  order,
}: {
  order: OrderPreviewModel | undefined;
}) {
  return (
    <Card>
      <Table className="w-full">
        <TableHeader>
          <TableRow>
            <TableHead>Item</TableHead>
            <TableHead>Size</TableHead>
            <TableHead>Qty</TableHead>
            <TableHead>Unit Price</TableHead>
            <TableHead>Line Price</TableHead>
          </TableRow>
        </TableHeader>
        {order ? (
          <TableBody>
            {order.items.map((item, idx) => (
              <TableRow key={item.description + " " + idx}>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.size ?? "-"}</TableCell>
                <TableCell>{item.qty}</TableCell>
                <TableCell>{curr(item.price)}</TableCell>
                <TableCell>{curr(item.price * item.qty)}</TableCell>
              </TableRow>
            ))}
            <tr>
              <TableCell colSpan={4} className="pt-2 text-right">
                Subtotal
              </TableCell>
              <TableCell className="pt-2 font-semibold">
                {curr(order.subtotal)}
              </TableCell>
            </tr>
            <tr>
              <TableCell colSpan={4} className="pt-2 text-right">
                Shipping
              </TableCell>
              <TableCell className="pt-2 font-semibold">
                {curr(order.shipping)}
              </TableCell>
            </tr>
            {order.inbound_freight ? (
              <tr>
                <TableCell colSpan={4} className="pt-2 text-right">
                  Inbound Freight
                </TableCell>
                <TableCell className="pt-2 font-semibold">
                  {curr(order.inbound_freight)}
                </TableCell>
              </tr>
            ) : null}
            <tr>
              <TableCell colSpan={4} className="pt-2 text-right">
                Tax
              </TableCell>
              <TableCell className="pt-2 font-semibold">
                Calculated Later
              </TableCell>
            </tr>
          </TableBody>
        ) : (
          <TableBodySkeleton numColumns={5} numRows={2} />
        )}
      </Table>
    </Card>
  );
}
