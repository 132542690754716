import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Sku } from "@/models";
import useDebounce from "@/hooks/useDebounce";
import SkuItem from "@/components/inventory/SkuItem";
import { INPUT_CLASSES } from "@/components/ui/input";

export default function SkuSelect({
  value,
  onChange,
}: {
  value: number | null;
  onChange: (value: number | null) => void;
}) {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");

  const debouncedQuery = useDebounce(query, 500);

  const { data: variants = [], isLoading } = useQuery(
    ["searchVariants", debouncedQuery],
    () =>
      axios
        .get<{ data: Sku[] }>(`/variants?query=${debouncedQuery}`)
        .then((res) => {
          return res.data.data.map((variant) => {
            queryClient.setQueryData(["variant", variant.id], variant);
            return variant;
          });
        }),
  );

  const { data: selectedVariant } = useQuery(
    ["variant", value],
    () => axios.get<Sku>(`/variants/${value}`).then((res) => res.data),
    {
      staleTime: 5 * 60 * 1000,
      enabled: value != null,
    },
  );

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("w-[300px] justify-between", INPUT_CLASSES)}
        >
          <span className="truncate">
            {value ? selectedVariant?.description : "Find SKU"}
          </span>

          <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0">
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search SKUs..."
            value={query}
            onValueChange={setQuery}
          />
          <CommandEmpty>
            {isLoading ? "Loading..." : "No SKUs found"}.
          </CommandEmpty>
          <CommandGroup className="max-h-[30rem] overflow-y-auto">
            {variants.map((variant) => (
              <CommandItem
                key={variant.id}
                value={String(variant.id)}
                onSelect={(currentValue) => {
                  onChange(
                    Number(currentValue) === value
                      ? null
                      : Number(currentValue),
                  );
                  setOpen(false);
                }}
                className="gap-x-2"
              >
                <SkuItem sku={variant} />

                <Check
                  className={cn(
                    "size-4",
                    value === variant.id ? "opacity-100" : "opacity-0",
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
