import { XIcon } from "lucide-react";
import { CustomerMembership } from "@/models";
import UserAvatar from "@/components/account/UserAvatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";

import { useAppState } from "@/context/AppContext";
import {
  useRemoveMembershipRequest,
  useUpdateMembershipRequest,
} from "@/api/membership";

export default function TeamMemberRow({
  membership,
  canInvite,
  canLeave,
}: {
  membership: CustomerMembership;
  canInvite: boolean;
  canLeave: boolean;
}) {
  const { user } = useAppState();

  const removeMembershipRequest = useRemoveMembershipRequest();
  const updateMembershipRequest = useUpdateMembershipRequest();

  return (
    <li className="flex items-center space-x-3 px-5 py-3">
      <UserAvatar user={membership.user} />
      <div className="flex-grow flex-col">
        <div>{membership.user.name}</div>
        <div className="text-sm text-muted-foreground">
          {membership.user.email}
        </div>
      </div>
      <div className="ml-auto flex items-center space-x-2">
        <DropdownMenu>
          <DropdownMenuTrigger
            disabled={!canInvite || membership.user.id === user.id}
          >
            <Badge variant="gray">{membership.role}</Badge>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuRadioGroup
              value={membership.role}
              onValueChange={(role) =>
                updateMembershipRequest.mutate({
                  id: membership.id,
                  role,
                })
              }
            >
              <DropdownMenuRadioItem value="admin" children="Admin" />
              <DropdownMenuRadioItem value="member" children="Member" />
              <DropdownMenuRadioItem value="guest" children="Guest" />
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        {canInvite && membership.user.id !== user.id && (
          <AlertDialog>
            <AlertDialogTrigger>
              <XIcon className="size-4 text-slate-500" />
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Remove User</AlertDialogTitle>
                <AlertDialogDescription>
                  Are you sure you want remove this user from your team?
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <Button
                  variant="destructive"
                  isLoading={removeMembershipRequest.isLoading}
                  onClick={() => removeMembershipRequest.mutate(membership.id)}
                >
                  Remove
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
        {canLeave && membership.user.id === user.id && (
          <AlertDialog>
            <AlertDialogTrigger>
              <XIcon className="size-4 text-slate-500" />
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Leave Team</AlertDialogTitle>
                <AlertDialogDescription>
                  Are you sure you want to leave this team?
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <Button
                  variant="destructive"
                  isLoading={removeMembershipRequest.isLoading}
                  onClick={() =>
                    removeMembershipRequest
                      .mutateAsync(membership.id)
                      .then(() => {
                        window.location.reload();
                      })
                  }
                >
                  Leave
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
      </div>
    </li>
  );
}
