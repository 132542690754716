import { Button } from "@/components/ui/button";
import { Product } from "@/models";
import { useState } from "react";
import { AddToCartPayload, useAddItem, useCartPreview } from "@/api/cart";
import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
} from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { useParams } from "react-router-dom";
import { curr } from "@/lib/curr";
import EmptyState from "@/components/ui/empty-state";
import { CircleOffIcon } from "lucide-react";
import AddToCartPreview from "@/components/cart/AddToCartPreview";
import { z } from "zod";
import pick from "lodash/pick";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import pluralize from "pluralize";

export default function AddToCartForm({ product }: { product: Product }) {
  const minQty = product.min_qty || 1;
  const schema = {
    complexity: z.coerce.number().int(),
  } as Record<string, z.ZodType>;
  product.variants.forEach((variant) => {
    schema[`variant${variant.id}`] = z.coerce.number().int().optional();
  });
  const formSchema = z.object(schema);

  const addToCartRequest = useAddItem();
  const [showAll, setShowAll] = useState(false);
  const { collection } = useParams<{ collection: string }>();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      complexity: 1,
    },
  });

  const values = form.watch();

  const valuesToPayload = (
    values: z.infer<typeof formSchema>,
  ): AddToCartPayload =>
    product.variants.reduce(
      (acc, variant) => {
        const qty = Number(values[`variant${variant.id}`] || 0);
        if (qty > 0) {
          acc.variants.push({ variant_id: variant.id, qty });
        }
        return acc;
      },
      {
        variants: [] as AddToCartPayload["variants"],
        collection,
        product_id: product.id,
        decoration: pick(values, ["complexity"]),
      },
    );

  const totalQty = product.variants.reduce(
    (acc, v) => acc + Number(values[`variant${v.id}`] || 0),
    0,
  );
  const isValid = form.formState.isValid && totalQty >= minQty;
  const previewQuery = useCartPreview(valuesToPayload(values), isValid);

  const inStockVariants = product.variants.filter(
    (v) => v.vendor_inventory_qty === null || v.vendor_inventory_qty > 0,
  );
  const hasMore = inStockVariants.length > 6;

  const minPrice = product.variants.reduce(
    (acc, variant) => Math.min(acc, variant.price),
    Infinity,
  );

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    addToCartRequest.mutateAsync(valuesToPayload(values)).then(() => {
      form.reset();
    });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="mt-4 max-w-xl space-y-4"
      >
        {product.decoration_method === "screenprint" && (
          <FormField
            name="complexity"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Logo Colors</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="number"
                    inputMode="numeric"
                    className="mb-4"
                    min={1}
                    max={4}
                    step={1}
                    onFocus={(e) => e.target.select()}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        )}

        {inStockVariants.length === 0 ? (
          <EmptyState
            Icon={CircleOffIcon}
            title="Out of Stock"
            description="This product is currently out of stock. Please check back later."
          />
        ) : product.variants.length === 1 ? (
          <FormField
            name={`variant${product.variants[0]!.id}`}
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Quantity</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="number"
                    inputMode="numeric"
                    className="mb-4"
                    min={1}
                    step={1}
                    onFocus={(e) => e.target.select()}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        ) : (
          <div className="overflow-hidden rounded-lg border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Size</TableHead>
                  <TableHead>Quantity</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {(showAll ? inStockVariants : inStockVariants.slice(0, 6)).map(
                  (variant) => (
                    <TableRow key={variant.id}>
                      <TableCell>
                        {variant.size}

                        {variant.price > minPrice && (
                          <span className="ml-2 text-xs text-muted-foreground">
                            +{curr(variant.price - minPrice)}
                          </span>
                        )}
                      </TableCell>
                      <TableCell className="py-2">
                        <FormField
                          name={`variant${variant.id}`}
                          control={form.control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              value={field.value || ""}
                              type="number"
                              inputMode="numeric"
                              className="h-9"
                              min={0}
                              step={1}
                              onFocus={(e) => e.target.select()}
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  ),
                )}
                {hasMore && (
                  <TableRow>
                    <TableCell colSpan={2} className="px-2 py-1">
                      <Button
                        onClick={() => setShowAll((prev) => !prev)}
                        size="sm"
                        variant="ghost"
                      >
                        {showAll
                          ? "Show less"
                          : `Show all ${pluralize(
                              "size",
                              inStockVariants.length,
                              true,
                            )}`}
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        )}

        <div className="px-3">
          <AddToCartPreview previewQuery={previewQuery} />
        </div>

        {inStockVariants.length > 0 && (
          <Button
            isLoading={addToCartRequest.isLoading}
            type="submit"
            className="mt-4 w-full"
            disabled={!isValid}
          >
            Add to Cart
          </Button>
        )}
      </form>
    </Form>
  );
}
