import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { FulfillmentOrder } from "@/models";

export function useGetFulfillmentOrder(id: number) {
  return useQuery(["fulfillmentOrder", id], () =>
    axios
      .get<FulfillmentOrder>(`/fulfillment-orders/${id}`)
      .then(({ data }) => data),
  );
}
