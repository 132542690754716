import { OrderItem, Quote } from "@/models";
import { Card } from "@/components/ui/card";
import { curr } from "@/lib/curr";
import {
  TotalTable,
  TotalTableLabel,
  TotalTableRow,
  TotalTableValue,
} from "@/components/ui/total-table";
import OrderItemsTable from "@/components/orders/OrderItemsTable";

export default function QuoteItems({
  quote,
  items,
}: {
  quote: Quote;
  items: OrderItem[];
}) {
  return (
    <div>
      <Card>
        <OrderItemsTable items={items} />
      </Card>
      <div className="mt-4 flex justify-end">
        <Card className="px-6 py-3">
          <TotalTable>
            <TotalTableRow>
              <TotalTableLabel>Subtotal</TotalTableLabel>
              <TotalTableValue>{curr(quote.subtotal)}</TotalTableValue>
            </TotalTableRow>
            <TotalTableRow>
              <TotalTableLabel>Shipping</TotalTableLabel>
              <TotalTableValue>
                {curr(quote.shipping + quote.inbound_freight)}
              </TotalTableValue>
            </TotalTableRow>
            <TotalTableRow>
              <TotalTableLabel>Tax</TotalTableLabel>
              <TotalTableValue>{curr(quote.tax)}</TotalTableValue>
            </TotalTableRow>
            <TotalTableRow>
              <TotalTableLabel>Total</TotalTableLabel>
              <TotalTableValue>{curr(quote.total)}</TotalTableValue>
            </TotalTableRow>
          </TotalTable>
        </Card>
      </div>
    </div>
  );
}
