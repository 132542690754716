import { Button } from "@/components/ui/button";
import { usePayRequest } from "@/lib/orders";
import { curr } from "@/lib/curr";
import { Project } from "@/models";

export default function ProjectPayButton({ project }: { project: Project }) {
  const payRequest = usePayRequest();

  if (project.balance && project.balance > 0) {
    return (
      <Button
        isLoading={payRequest.isLoading}
        onClick={() =>
          payRequest.mutate({
            invoices: project.orders.map((o) => o.code),
          })
        }
      >
        Pay {curr(project.balance)}
      </Button>
    );
  }
  return null;
}
