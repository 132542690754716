import { ReactNode } from "react";
import ConfirmationButton from "@/components/ui/confirmation-button";
import { ProjectOption } from "@/models";
import { projectOptionToPayload } from "@/lib/projects";
import { useUpdateProjectOption } from "@/api/project-options";
import { useToast } from "@/components/ui/use-toast";

export default function ProjectOptionSampleOrderButton({
  cb,
  children,
  mode = "add",
  option,
  orderId,
}: {
  cb?: () => void;
  children: ReactNode;
  mode: "add" | "delete";
  option: ProjectOption;
  orderId?: number;
}) {
  const updateOption = useUpdateProjectOption(option.id);
  const { toast } = useToast();
  const confirmUpdateOption = (sample_order_id: number | null) => {
    updateOption
      .mutateAsync({
        ...projectOptionToPayload(option),
        sample_order_id,
      })
      .then(() => {
        toast({
          title:
            sample_order_id !== null
              ? "Order Assigned to Option"
              : "Order Unassigned from Option",
          variant: "success",
        });
        cb ? cb() : null;
      })
      .catch(() =>
        toast({
          title: "There was a problem assigning this order to option",
          variant: "error",
        }),
      );
  };
  return (
    <>
      {mode === "add" && orderId ? (
        <ConfirmationButton
          buttonLabel="Confirm"
          className="width-100"
          title="Assign Order to Option"
          message="Are you sure you want to link this order to this option?"
          isLoading={updateOption.isLoading}
          onClick={() => confirmUpdateOption(orderId)}
          variant="default"
        >
          {children}
        </ConfirmationButton>
      ) : (
        <ConfirmationButton
          buttonLabel="Remove"
          className="width-100"
          title="Remove Order from Option"
          message="Are you sure you want to remove this order from this option?"
          isLoading={updateOption.isLoading}
          onClick={() => confirmUpdateOption(null)}
          variant="default"
        >
          {children}
        </ConfirmationButton>
      )}
    </>
  );
}
