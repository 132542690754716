import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ProjectOption, ProjectOptionPayload } from "@/models";
import useDebounce from "@/hooks/useDebounce";
import { useProject } from "@/context/ProjectContext";
import orderBy from "lodash/orderBy";

export interface OptionsResponse {
  data: ProjectOption[];
  trashed: ProjectOption[];
}

export function useProjectOptions() {
  const project = useProject();
  return useQuery(["projectOptions", project.id], () =>
    axios
      .get<OptionsResponse>(`projects/${project.id}/options`)
      .then(({ data }) => data),
  );
}

export function usePreviousProjectOptions(query: string, getPinned: boolean) {
  const debouncedQuery = useDebounce(query, 500);
  const filter = useMemo(
    () => (getPinned ? { is_pinned: 1 } : {}),
    [getPinned],
  );

  return useQuery(
    ["previousProjectOptions", debouncedQuery, getPinned],
    () =>
      axios
        .get("/options/user-options", {
          params: {
            filter,
            query: debouncedQuery || "",
          },
        })
        .then((response) => response.data.data),
    {
      enabled: true,
      keepPreviousData: true,
    },
  );
}

function onAddOption(option: ProjectOption) {
  return (prev: OptionsResponse | undefined) => {
    if (!prev) return prev;
    return {
      ...prev,
      data: [...prev.data, option],
    };
  };
}

export function useCreateProjectOption() {
  const queryClient = useQueryClient();
  const project = useProject();

  return useMutation((data: ProjectOptionPayload) =>
    axios
      .post<ProjectOption>(`projects/${project.id}/options`, data)
      .then(({ data }) => {
        queryClient.setQueryData<OptionsResponse>(
          ["projectOptions", project.id],
          onAddOption(data),
        );
        return data;
      }),
  );
}

export function useUpdateProjectOption(optionId: number) {
  const queryClient = useQueryClient();
  const project = useProject();

  return useMutation((data: ProjectOptionPayload) =>
    axios
      .put<ProjectOption>(`projects/${project.id}/options/${optionId}`, data)
      .then(({ data }) => {
        queryClient.setQueryData<OptionsResponse>(
          ["projectOptions", project.id],
          (prev) => {
            if (!prev) return prev;
            return {
              ...prev,
              data: prev.data.map((p) => {
                return p.id === data.id ? data : p;
              }),
            };
          },
        );
        return data;
      }),
  );
}

export function useDeleteProjectOption() {
  const queryClient = useQueryClient();
  const project = useProject();

  return useMutation((optionId: number) =>
    axios.delete(`projects/${project.id}/options/${optionId}`).then(() => {
      queryClient.setQueryData<OptionsResponse>(
        ["projectOptions", project.id],
        (prev) => {
          if (!prev) return prev;
          const deleted = prev.data.find((p) => p.id === optionId);
          return {
            data: prev.data.filter((p) => p.id !== optionId),
            trashed: [...prev.trashed, deleted!],
          };
        },
      );
    }),
  );
}

export function useDuplicateProjectOption() {
  const queryClient = useQueryClient();
  const project = useProject();

  return useMutation((optionId: number) =>
    axios
      .post<ProjectOption>(
        `projects/${project.id}/options/${optionId}/duplicate`,
      )
      .then(({ data }) => {
        queryClient.setQueryData<OptionsResponse>(
          ["projectOptions", project.id],
          onAddOption(data),
        );
      }),
  );
}

export function useSortProjectOptions() {
  const project = useProject();
  const queryClient = useQueryClient();
  return useMutation((ids: number[]) => {
    queryClient.setQueryData<OptionsResponse>(
      ["projectOptions", project.id],
      (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          data: orderBy(prev.data, (o) => ids.indexOf(o.id)),
        };
      },
    );

    return axios
      .post<OptionsResponse>(`projects/${project.id}/options/sort`, {
        options: ids,
      })
      .then(({ data }) => {
        queryClient.setQueryData<OptionsResponse>(
          ["projectOptions", project.id],
          data,
        );
        return data.data;
      });
  });
}

export function useRestoreProjectOption() {
  const queryClient = useQueryClient();
  const project = useProject();

  return useMutation((optionId: number) =>
    axios
      .post<ProjectOption>(`projects/${project.id}/options/${optionId}/restore`)
      .then(({ data }) => {
        queryClient.setQueryData<OptionsResponse>(
          ["projectOptions", project.id],
          (prev) => {
            if (!prev) return prev;
            return {
              data: [...prev.data, data],
              trashed: prev.trashed.filter((p) => p.id !== optionId),
            };
          },
        );
      }),
  );
}

export function useDuplicateColorOption() {
  const queryClient = useQueryClient();
  const project = useProject();

  return useMutation(
    ({ optionId, product_id }: { optionId: number; product_id: number }) =>
      axios
        .post<ProjectOption>(
          `projects/${project.id}/options/${optionId}/duplicate-color`,
          {
            product_id,
          },
        )
        .then(({ data }) => {
          queryClient.setQueryData<OptionsResponse>(
            ["projectOptions", project.id],
            (prev) => {
              if (!prev) return prev;
              return {
                ...prev,
                data: [...prev.data, data],
              };
            },
          );
          return data;
        }),
  );
}
