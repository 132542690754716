import { useProject } from "@/context/ProjectContext";
import { z } from "zod";
import AddressFields from "@/components/addresses/AddressFields";
import { Button } from "@/components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@/components/ui/form";
import { AddressPayload, ZodAddress } from "@/models";

const formSchema = z.object({
  address: ZodAddress,
});

export default function ProjectAddressForm({
  onSubmit,
}: {
  onSubmit: (values: { address: AddressPayload }) => Promise<void>;
}) {
  const project = useProject();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      address: project.address || undefined,
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
        <AddressFields form={form} />
        <Button type="submit" isLoading={form.formState.isSubmitting}>
          Save
        </Button>
      </form>
    </Form>
  );
}
