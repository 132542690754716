import Title from "@/components/ui/title";
import { Link, useParams } from "react-router-dom";
import { Skeleton } from "@/components/ui/skeleton";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import Toolbar from "@/components/ui/toolbar";
import { Dialog, DialogTrigger, DialogContent } from "@/components/ui/dialog";
import ApproveForm from "@/components/approvals/ApproveForm";
import DeclineForm from "@/components/approvals/DeclineForm";
import { cn } from "@/lib/utils";
import Description from "@/components/ui/description";
import { intlFormat } from "date-fns";
import ApprovalSection from "@/components/approvals/ApprovalSection";
import { Card } from "@/components/ui/card";
import { AlertTriangle, XIcon } from "lucide-react";
import { useGetApproval } from "@/api/approval";
import { CheckIcon } from "@heroicons/react/20/solid";
import NotFound from "@/pages/NotFound";
import { useTitle } from "@/lib/title";

export default function Approval() {
  const { code } = useParams<{ code: string }>();
  const { data: approval, isLoading } = useGetApproval(code!);

  useTitle(
    approval ? `${approval.label} ${approval.order.increment_id}` : "Approval",
  );

  if (isLoading) {
    return <Skeleton className="h-4" />;
  }

  if (!approval) {
    return <NotFound />;
  }

  const decided_at = approval.approved_at || approval.rejected_at;

  const actions =
    approval.status === "Pending" ? (
      <>
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="default">
              <CheckIcon className="mr-2 size-5" />
              Approve
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px]">
            <ApproveForm approval={approval} />
          </DialogContent>
        </Dialog>
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="outline">
              <XIcon className="mr-2 size-5" />
              Decline
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px]">
            <DeclineForm approval={approval} />
          </DialogContent>
        </Dialog>
      </>
    ) : (
      <div className="flex flex-col items-end">
        <Badge
          className={cn("mb-2 px-4 py-1 text-base")}
          variant={approval.status === "Approved" ? "green" : "red"}
        >
          {approval.status === "Approved" ? (
            <CheckIcon className="mr-2 size-5" />
          ) : (
            <XIcon className="mr-2 size-5" />
          )}
          {approval.status}
        </Badge>
        <div className="text-xs">
          <span>by {approval.decider_name} on </span>
          {decided_at ? (
            <span>{intlFormat(new Date(decided_at))}</span>
          ) : (
            <span>?</span>
          )}
        </div>
        {approval.rejection_note && (
          <div className="mt-1 text-xs text-muted-foreground">
            &ldquo;{approval.rejection_note}&rdquo;
          </div>
        )}
      </div>
    );

  const { order } = approval;

  return (
    <div className="flex-1">
      <Toolbar actions={actions} className="mb-8">
        <Title>
          {approval.label} {order.increment_id}
        </Title>
        <Description>
          Please review the {approval.label} below. Your order will remain on
          hold until approval is received.
        </Description>
      </Toolbar>

      <div className="space-y-8">
        {approval.waiting_on_payment && !approval.project_id && (
          <Card className="flex items-center justify-between px-4 py-2">
            <div className="flex items-center">
              <AlertTriangle className="mr-2 size-5 text-yellow-500" />
              Payment is required upfront for this order. Your order will remain
              on hold until payment is received.
            </div>
            <Button asChild>
              <Link to={`/guest/orders/${approval.order_code}`}>
                Pay for Order &rarr;
              </Link>
            </Button>
          </Card>
        )}

        {approval.waiting_on_po && !approval.project_id && (
          <Card className="flex items-center justify-between px-4 py-2">
            <div className="flex items-center">
              <AlertTriangle className="mr-2 size-5 text-yellow-500" />A
              Purchase Order is required upfront for this order. Your order will
              remain on hold until a PO is received.
            </div>
            <Button asChild>
              <Link to={`/guest/orders/${approval.order_code}`}>
                Provide PO &rarr;
              </Link>
            </Button>
          </Card>
        )}

        {approval.sealed_html ? (
          <div dangerouslySetInnerHTML={{ __html: approval.sealed_html }} />
        ) : (
          <ApprovalSection approval={approval} />
        )}
      </div>
    </div>
  );
}
