import stringReplace from "react-string-replace";
import { Paperclip } from "lucide-react";
import { imageUrl } from "@/lib/image";
import { ProjectMessage } from "@/models";
import { cn } from "@/lib/utils";
import EnhancedText from "@/components/ui/enhanced-text";

export const BUBBLE_CLASS = "rounded-xl border px-3 py-1 bg-muted";

export function ProjectMessageBubble({
  message,
  isMe,
}: {
  message: string;
  isMe: boolean;
}) {
  return (
    <EnhancedText
      className={cn(
        BUBBLE_CLASS,
        isMe && "border-transparent bg-primary text-primary-foreground",
      )}
      linkClassName={isMe ? "text-primary-foreground" : ""}
    >
      {stringReplace(message, /@\[(.*?)\]\(\d+\)/g, (m) => (
        <span className="font-medium">{m}</span>
      ))}
    </EnhancedText>
  );
}

export default function ProjectMessageItem({
  message,
  isMe,
}: {
  message: ProjectMessage;
  isMe: boolean;
}) {
  return (
    <div
      className={cn("ml-3 mt-2 flex flex-col items-start space-y-1 text-sm")}
    >
      {message.message && (
        <ProjectMessageBubble isMe={isMe} message={message.message} />
      )}

      {message.attachments.length > 0 && (
        <ul className="flex-grow">
          {message.attachments.map((a) => (
            <li
              key={a.file}
              className={cn(
                "flex items-center gap-2 truncate py-1 text-sm text-primary",
                isMe ? "flex-row-reverse" : "flex-row",
              )}
            >
              <Paperclip className="size-4" />
              <a target="blank" href={imageUrl(a.url, { dl: 1 })}>
                {a.name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
