import ReactDOM from "react-dom/client";
import App from "./App";
import "./App.css";
import axios from "axios";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  ALLOWS_SIGNUP,
  API_BASE_URL,
  APP_ENV,
  GOOGLE_CLIENT_ID,
  MICROSOFT_CLIENT_ID,
} from "@/constants/services";
import AppWrapper from "@/layouts/AppWrapper";
import * as Sentry from "@sentry/react";
import { initializeCustomerId } from "@/lib/auth";

if (APP_ENV !== "local") {
  Sentry.init({
    environment: APP_ENV,
    dsn: "https://460022fac4f9e0e66c25ecc0790beda9@o4507416470028288.ingest.us.sentry.io/4507431835860993",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^http:\/\/avail\.test\/customer-api/,
      /^https:\/\/avail\.brandrpm\.io\/customer-api/,
      /^https:\/\/availerp\.com\/customer-api/,
      /^https:\/\/api\.availswag\.com\/customer-api/,
    ],
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ["Failed to fetch"],
  });
}

axios.defaults.baseURL = API_BASE_URL + "customer-api/";
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.headers.common.Accept = "application/json";
axios.defaults.headers.common["X-Allows-Signup"] = String(ALLOWS_SIGNUP);
initializeCustomerId();

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: MICROSOFT_CLIENT_ID,
  },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <MsalProvider instance={msalInstance}>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <AppWrapper>
        <App />
      </AppWrapper>
    </GoogleOAuthProvider>
  </MsalProvider>,
);
