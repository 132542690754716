import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Vendor } from "@/models";
import useDebounce from "@/hooks/useDebounce";
import { INPUT_CLASSES } from "@/components/ui/input";

export default function VendorSelect({
  value,
  onChange,
}: {
  value: number | undefined;
  onChange: (value: number) => void;
}) {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");

  const debouncedQuery = useDebounce(query, 500);

  const { data: vendors = [] } = useQuery(
    ["searchVendors", debouncedQuery],
    () =>
      axios
        .get<{ data: Vendor[] }>(`/vendors?query=${debouncedQuery}`)
        .then((res) => {
          return res.data.data.map((vendor) => {
            queryClient.setQueryData(["vendor", vendor.id], vendor);
            return vendor;
          });
        }),
  );

  const { data: selectedVendor } = useQuery(
    ["vendor", value],
    () => axios.get<Vendor>(`/vendors/${value}`).then((res) => res.data),
    {
      staleTime: 5 * 60 * 1000,
      enabled: value != null,
    },
  );

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("w-full justify-between", INPUT_CLASSES)}
        >
          <span className="truncate">
            {value ? selectedVendor?.name : "Find Vendor"}
          </span>

          <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0">
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search Vendors..."
            value={query}
            onValueChange={setQuery}
          />
          <CommandEmpty>No Vendor found.</CommandEmpty>
          <CommandGroup className="max-h-[30rem] overflow-y-auto">
            {vendors.map((vendor) => (
              <CommandItem
                key={vendor.id}
                value={String(vendor.id)}
                onSelect={(currentValue) => {
                  onChange(Number(currentValue));
                  setOpen(false);
                }}
              >
                {vendor.name}
                <Check
                  className={cn(
                    "ml-2 size-4",
                    value === vendor.id ? "opacity-100" : "opacity-0",
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
