import { Shipment } from "@/models";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { intlFormat } from "date-fns";

export default function ShipmentsTable({
  shipments,
}: {
  shipments: Shipment[];
}) {
  if (shipments.length === 0) {
    return null;
  }
  return (
    <Card>
      <CardHeader className="border-b">
        <CardTitle className="text-lg">Shipments</CardTitle>
      </CardHeader>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Date</TableHead>
            <TableHead>Carrier</TableHead>
            <TableHead>Tracking Number</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {shipments.map((shipment) => (
            <TableRow key={shipment.id}>
              <TableCell>{intlFormat(new Date(shipment.created_at))}</TableCell>
              <TableCell>{shipment.carrier_name || shipment.carrier}</TableCell>
              <TableCell>
                {shipment.tracking_url ? (
                  <a href={shipment.tracking_url} target="_blank">
                    {shipment.tracking_number}
                  </a>
                ) : (
                  <span>&mdash;</span>
                )}
              </TableCell>
            </TableRow>
          ))}
          {shipments.length === 0 && (
            <TableRow>
              <TableCell colSpan={0}>
                There are no shipments for this order
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Card>
  );
}
