import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Invite } from "@/models";

export function useGetInvites() {
  return useQuery(["invites"], () =>
    axios
      .get<{
        data: Invite[];
      }>("/invites")
      .then(({ data }) => data.data),
  );
}

export function useRemoveInviteRequest() {
  const queryClient = useQueryClient();
  return useMutation((id: number) => axios.delete(`/invites/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(["invites"]);
    },
  });
}
