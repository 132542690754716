import { Project } from "@/models";

export const CREATE_PROJECT_STORAGE_KEY = "createProjectPayload";

export const SHIPPING_MODE_LABELS: Record<Project["shipping_mode"], string> = {
  bulk: "Ship to Single Address",
  kits: "Ship to Multiple Addresses",
  inventory: "Add to Inventory",
};

export const DECORATION_METHODS = [
  "screenprint",
  "embroidery",
  "ad_specialty",
  "heat_transfer",
  "blanks",
] as const;

export type DecorationMethod = (typeof DECORATION_METHODS)[number];

export const FEES: string[] = [
  "inbound_shipping",
  "outbound_shipping",
  "standard_shipping",
  "expedited_shipping",
  "pms_charge",
  "digitizing",
  "vectorizing",
  "setup_fee",
  "rush_fee",
  "run_charge",
  "ltm_charge",
];
