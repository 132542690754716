import Title from "@/components/ui/title";
import Toolbar from "@/components/ui/toolbar";
import FulfillmentOrderForm from "@/components/fulfillmentOrders/FulfillmentOrderForm";
import { useTitle } from "@/lib/title";

export default function FulfillmentOrderCreate() {
  useTitle("Create Fulfillment Order");

  return (
    <div className="flex-1 space-y-6">
      <Toolbar>
        <Title>Create Fulfillment Order</Title>
      </Toolbar>

      <FulfillmentOrderForm />
    </div>
  );
}
