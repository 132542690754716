import ProjectItemRow from "@/components/projects/ProjectItemRow";
import ProjectItemProvidedOptions from "@/components/projects/ProjectItemProvidedOptions";
import { useProject } from "@/context/ProjectContext";
import { Alert } from "@/components/ui/alert";
import { useProjectSelections } from "@/api/project-selections";
import { curr } from "@/lib/curr";
import ProjectAction from "@/components/projects/ProjectAction";
import FloatingBar, { InlineLabeledValue } from "@/components/ui/floating-bar";
import { useProjectOptions } from "@/api/project-options";
import { ShoppingCart } from "lucide-react";
import pluralize from "pluralize";
import { getRequestProofsDescription } from "@/lib/projects";

export default function OptionsProvidedStatus() {
  const project = useProject();
  const { data: selectionsRes } = useProjectSelections();
  const { data: optionsRes, isLoading } = useProjectOptions();

  const selections = selectionsRes?.data || [];
  const options = optionsRes?.data || [];

  return (
    <div>
      <Alert variant="info">
        Check out what our team has put together for you! Once you've made your
        selections, it's time to request proofs. If you’d like to see more,
        &quot;Request More Options&quot;.
      </Alert>

      <ul className="mt-4 space-y-4">
        {project.items.map((item) => (
          <li key={item.id} className="space-y-4">
            <ProjectItemRow item={item} />
            <ProjectItemProvidedOptions
              item={item}
              selections={selections}
              options={options.filter((o) => o.project_item_id === item.id)}
              optionsLoading={isLoading}
            />
          </li>
        ))}
      </ul>

      <FloatingBar
        action={
          <>
            <ProjectAction
              status="options_requested"
              label="Request More Options"
              description="This will send a request back to Avail for more options. Please explain what you are looking for in the field below."
              direction="prev"
              withNote
            />
            <ProjectAction
              status="proofs_requested"
              label={pluralize("Request Proof", selections.length, false)}
              disabled={selections.length === 0}
              description={getRequestProofsDescription(project, selections)}
              direction="next"
            />
          </>
        }
      >
        <ShoppingCart className="size-5" />
        <InlineLabeledValue
          label="Options Selected"
          value={selectionsRes ? selectionsRes.count : "-"}
        />
        <InlineLabeledValue
          label="Estimated Subtotal"
          value={selectionsRes ? curr(selectionsRes.subtotal) : "-"}
        />
      </FloatingBar>
    </div>
  );
}
