import { useProject } from "@/context/ProjectContext";
import Toolbar from "@/components/ui/toolbar";
import ProjectDuplicateForm from "@/components/projects/ProjectDuplicateForm";
import { Button } from "@/components/ui/button";
import ProjectAction from "@/components/projects/ProjectAction";
import ProjectTitle from "@/components/projects/ProjectTitle";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Ban, CopyPlus, MoreVerticalIcon } from "lucide-react";
import { canEdit } from "@/lib/projects";

export default function ProjectHeader() {
  const project = useProject();

  return (
    <Toolbar
      actions={
        project.status === "cancelled" ? (
          <ProjectAction
            status="draft"
            label="Restore Project"
            direction="next"
            description="The will restore the project to the draft status."
          >
            <Button variant="outline">Restore</Button>
          </ProjectAction>
        ) : (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="icon" variant="outline">
                <MoreVerticalIcon className="h-5 w-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <ProjectDuplicateForm>
                <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                  <CopyPlus className="mr-2 h-4 w-4" />
                  Duplicate
                </DropdownMenuItem>
              </ProjectDuplicateForm>

              {canEdit(project) && (
                <ProjectAction
                  status="cancelled"
                  label="Cancel Project"
                  direction="next"
                  buttonVariant="destructive"
                  description="Are you sure you want to cancel this project?"
                >
                  <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                    <Ban className="mr-2 h-4 w-4" />
                    Cancel
                  </DropdownMenuItem>
                </ProjectAction>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        )
      }
    >
      <ProjectTitle />
    </Toolbar>
  );
}
