import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Sidenav from "@/components/navigation/Sidenav";
import AccountDropdownMenu from "@/components/navigation/AccountDropdownMenu";
import UserAvatar from "@/components/account/UserAvatar";
import { useLocation } from "react-router-dom";
import { useAppState } from "@/context/AppContext";
import { useWatchForNewNotifications } from "@/api/notifications";
import Logo from "@/components/auth/Logo";

export default function PrimaryNavigation({
  isCollapsed,
}: {
  isCollapsed?: boolean;
}) {
  const { user } = useAppState();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();

  useWatchForNewNotifications();

  useEffect(() => {
    setSidebarOpen(false);
  }, [pathname]);

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-slate-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="size-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <Sidenav className="h-full" />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Sidenav
        className="fixed inset-y-0 left-0 z-10 hidden lg:flex"
        isCollapsed={isCollapsed}
      />
      <div className="fixed inset-x-0 z-10 flex h-14 items-center bg-white/95 px-6 shadow lg:hidden">
        <Bars3Icon
          className="mr-6 size-6"
          onClick={() => setSidebarOpen(true)}
        />
        <Logo className="h-7 fill-current" />

        <AccountDropdownMenu>
          <button type="button" className="ml-auto">
            <UserAvatar user={user} className="size-8" />
          </button>
        </AccountDropdownMenu>
      </div>
    </>
  );
}
