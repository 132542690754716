import { z } from "zod";
import Title from "@/components/ui/title";
import Description from "@/components/ui/description";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RadioGroup } from "@headlessui/react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { RadioOption } from "@/components/ui/radio-option";
import { Label } from "@/components/ui/label";
import { DatePicker } from "@/components/ui/date-picker";
import { addDays } from "date-fns";
import AddressFormSection from "@/components/addresses/AddressFormSection";
import { Alert } from "@/components/ui/alert";
import { SHIPPING_MODE_LABELS } from "@/constants/project";
import { useSettings } from "@/context/AppContext";
import Toolbar from "@/components/ui/toolbar";
import WizardStep from "@/components/wizard/WizardStep";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";
import { ZodAddress } from "@/models";

const formSchema = z
  .object({
    in_hands_date: z.coerce.date({
      errorMap: () => ({
        message: "Please select a date",
      }),
    }),
  })
  .and(
    z.discriminatedUnion("shipping_mode", [
      z.object({
        shipping_mode: z.literal("bulk"),
        address: ZodAddress,
      }),
      z.object({
        shipping_mode: z.literal("kits"),
      }),
      z.object({
        shipping_mode: z.literal("inventory"),
      }),
    ]),
  );

type FormShape = z.infer<typeof formSchema>;

export default function ProjectDelivery({
  defaultValues,
  onSubmit,
}: {
  defaultValues: Partial<FormShape>;
  onSubmit: (v: FormShape) => Promise<void>;
}) {
  const form = useForm<FormShape>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });
  const settings = useSettings();
  const shippingMode = form.watch("shipping_mode");

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <WizardStep>
          <Toolbar>
            <Title>Let us know how and when to deliver</Title>
            <Description>
              When do you need your items by? Do you need them shipped to one
              location or multiple?
            </Description>
          </Toolbar>

          <FormField
            control={form.control}
            name="in_hands_date"
            render={({ field }) => (
              <FormItem>
                <FormLabel>In-Hands Date</FormLabel>
                <FormControl>
                  <DatePicker
                    date={field.value}
                    setDate={(d) => field.onChange(d as Date)}
                    calendarProps={{
                      fromDate: addDays(new Date(), 1),
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="shipping_mode"
            render={({ field }) => (
              <FormItem>
                <RadioGroup value={field.value} onChange={field.onChange}>
                  <RadioGroup.Label as={Label}>
                    Select a shipping method
                  </RadioGroup.Label>
                  <div className="mt-2 grid grid-cols-1 gap-3 lg:grid-cols-3 lg:gap-6">
                    <RadioOption
                      value="bulk"
                      label={SHIPPING_MODE_LABELS["bulk"]}
                      description="Ship all items to one address"
                    />
                    <RadioOption
                      value="kits"
                      label={SHIPPING_MODE_LABELS["kits"]}
                      description="Items will be kitted and shipped to multiple addresses"
                    />
                    {settings.is_fulfillment && (
                      <RadioOption
                        value="inventory"
                        label={SHIPPING_MODE_LABELS["inventory"]}
                        description="Items will be added to your inventory for future use"
                      />
                    )}
                  </div>
                </RadioGroup>
              </FormItem>
            )}
          />

          {shippingMode === "bulk" && <AddressFormSection form={form} />}

          {shippingMode === "kits" && (
            <Alert variant="info">
              Our team will reach out to get addresses when it's time to kit &
              ship your project.
            </Alert>
          )}

          <WizardSubmitButton>Next</WizardSubmitButton>
        </WizardStep>
      </form>
    </Form>
  );
}
