import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { OrderApproval } from "@/models";
import { useSetBusiness } from "@/context/BusinessContext";

export function useGetApproval(code: string) {
  const setBusiness = useSetBusiness();

  return useQuery(["approval", code], () =>
    axios.get<OrderApproval>(`/approvals/${code}`).then(({ data }) => {
      setBusiness(data.business);
      return data;
    }),
  );
}
