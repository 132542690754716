import ProjectItemRow from "@/components/projects/ProjectItemRow";
import { useProject } from "@/context/ProjectContext";
import Gate from "@/components/roles/Gate";
import ProjectItemProvideOptions from "@/components/projects/ProjectItemProvideOptions";
import { Alert } from "@/components/ui/alert";
import ProjectAction from "@/components/projects/ProjectAction";
import FloatingBar, { InlineLabeledValue } from "@/components/ui/floating-bar";
import { useProjectOptions } from "@/api/project-options";
import TrashedOptions from "@/components/projects/TrashedOptions";
import { Link } from "react-router-dom";
import { Plus } from "lucide-react";

export default function OptionsRequestedStatus() {
  const { items } = useProject();
  const { data, isLoading } = useProjectOptions();
  const options = data?.data || [];

  return (
    <div className="space-y-4">
      <Alert variant="info">
        Our team is working on sourcing options for each of your categories.
        Hang tight and we'll let you know when the options are ready to review!
      </Alert>

      <ul className="space-y-4">
        {items.map((item) => (
          <li key={item.id} className="space-y-4">
            <ProjectItemRow item={item} />

            <Gate role={["avail", "avail_incognito"]}>
              <ProjectItemProvideOptions
                item={item}
                options={options.filter((o) => o.project_item_id === item.id)}
                optionsLoading={isLoading}
              />
            </Gate>
          </li>
        ))}
      </ul>

      <div>
        <Link
          to="./add-item"
          className="inline-flex items-center text-sm font-medium text-primary"
        >
          Add Category <Plus className="ml-2 size-4" />
        </Link>
      </div>

      <TrashedOptions />

      <Gate role={["avail", "avail_incognito"]}>
        <FloatingBar
          action={
            <ProjectAction
              status="options_provided"
              label="Submit Options to Customer"
              description="This will send a notification to the customer that you have completed sourcing."
              direction="next"
            />
          }
        >
          <InlineLabeledValue label="Options Provided" value={options.length} />
        </FloatingBar>
      </Gate>
    </div>
  );
}
