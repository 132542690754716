import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import useDebounce from "@/hooks/useDebounce";
import {
  ProjectOptionPayload,
  SageProductDetail,
  SageSearchProduct,
} from "@/models";

export function useSearchSageProducts(query: string) {
  const debouncedQuery = useDebounce(query, 500);

  return useQuery<SageSearchProduct[], Error>(
    ["searchSageProducts", debouncedQuery],
    () =>
      axios
        .get<SageSearchProduct[]>(`/sage/search?query=${debouncedQuery}`)
        .then(({ data }) => data),
    {
      enabled: !!debouncedQuery,
    },
  );
}

export function useGetSageProductDetails(prodEId: number) {
  return useQuery<SageProductDetail, Error>(
    ["getSageProductDetails", prodEId],
    () =>
      axios
        .get<SageProductDetail>(`/sage/get?query=${prodEId}`)
        .then(({ data }) => data),
  );
}

export function useSelectSageProduct() {
  return useMutation(
    (data: {
      color: string | null;
      prodEId: number;
      project_item_id: number;
    }) =>
      axios
        .post<ProjectOptionPayload>("/sage/select", data)
        .then(({ data }) => data),
  );
}
