import Title from "@/components/ui/title";
import {
  useExport,
  useManualTableQuery,
  useManualTableState,
} from "@/lib/table";
import { Sku } from "@/models";
import StatCard from "@/components/ui/stat-card";
import InventoryTable from "@/components/inventory/InventoryTable";
import { curr } from "@/lib/curr";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useTitle } from "@/lib/title";
import { DataTableActions } from "@/components/ui/data-table-actions";
import { Table } from "@tanstack/react-table";

export default function Inventory() {
  useTitle("Inventory");
  const tableState = useManualTableState(
    [
      {
        id: "sku",
        desc: false,
      },
    ],
    { stock_status: "in_stock" },
  );
  const { setQuery } = tableState;

  const downloadRequest = useExport("/variants", tableState);

  const result = useManualTableQuery<
    Sku,
    {
      sku_sum: number;
      inventory_order_subtotal: number;
      value_sum: number;
      skus_missing_value: number;
    }
  >("/variants", tableState);
  const { data, isLoading } = result;

  return (
    <div className="flex-1 space-y-6">
      <Title>SKUs</Title>
      <Tabs
        value={tableState.filters.stock_status}
        onValueChange={(v) =>
          tableState.setFilters((prev) => ({ ...prev, stock_status: v }))
        }
      >
        <TabsList className="mb-6">
          <TabsTrigger value="in_stock">In Stock</TabsTrigger>
          <TabsTrigger value="low_stock">Low Stock</TabsTrigger>
          <TabsTrigger value="out_of_stock">Out of Stock</TabsTrigger>
          <TabsTrigger value="all">All</TabsTrigger>
        </TabsList>
      </Tabs>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
        <StatCard
          title="Total SKUs"
          value={data?.meta.total.toLocaleString()}
          isLoading={isLoading}
        />
        <StatCard
          title="Total Units"
          value={data?.sku_sum.toLocaleString()}
          isLoading={isLoading}
        />
        <StatCard
          title="Remaining Value"
          value={curr(data?.value_sum)}
          isLoading={isLoading}
        />
        <StatCard
          title="Total Purchased"
          value={curr(data?.inventory_order_subtotal)}
          isLoading={isLoading}
        />
      </div>
      <InventoryTable
        header={(table: Table<Sku>) => (
          <DataTableActions
            canFilterByWebstore
            filters={tableState.filters}
            downloadRequest={downloadRequest}
            placeholder="Search by SKU or Description"
            setFilters={tableState.setFilters}
            setQuery={setQuery}
            table={table}
          />
        )}
        tableState={tableState}
        query={result}
      />
    </div>
  );
}
