import CardPage from "@/layouts/CardPage";

export default function SignupNotAllowed() {
  return (
    <CardPage
      title="Unable to find account"
      description="We were not able to match your email with an existing account.  Please contact your sales representative to get access to the portal."
    />
  );
}
