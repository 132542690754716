import { Link } from "react-router-dom";
import { cn } from "@/lib/utils";
import { imageUrl } from "@/lib/image";
import { Product } from "@/models";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import { useIsMobile } from "@/lib/screen";

export default function ProductColors({
  product,
  colors,
}: {
  product: Product;
  colors: Product[];
}) {
  const divRef = useRef<HTMLDivElement | null>(null);
  const [showAll, setShowAll] = useState(false);
  const [numColorsOnOneRow, setNumColorsOnOneRow] = useState(
    useIsMobile() ? 6 : 12,
  );
  const colorsToShow = showAll ? colors : colors.slice(0, numColorsOnOneRow);
  const hasMore = colors.length > numColorsOnOneRow;

  const handleScreenSizeChange = useCallback(() => {
    if (divRef.current) {
      setNumColorsOnOneRow(divRef.current.getBoundingClientRect().width / 44);
    }
  }, [divRef, setNumColorsOnOneRow]);

  useLayoutEffect(() => {
    handleScreenSizeChange();

    window.addEventListener("resize", handleScreenSizeChange);
    return () => window.removeEventListener("resize", handleScreenSizeChange);
  }, [handleScreenSizeChange]);

  return (
    <div className="my-5">
      <div className="flex flex-wrap gap-2" ref={divRef}>
        {colorsToShow.map((color) => (
          <Link
            key={color.id}
            className={cn(
              "inline-block size-9 overflow-hidden rounded-full border",
              color.id === product.id && "ring-1 ring-primary ring-offset-2",
            )}
            style={{
              backgroundColor: color.color_hex
                ? `#${color.color_hex}`
                : "transparent",
            }}
            title={color.color || color.name}
            to={`../${color.slug}`}
          >
            {color.swatch_image ? (
              <img
                alt=""
                className="swatch-image h-full w-full object-cover"
                src={color.swatch_image}
              />
            ) : color.color_hex ? null : (
              <img
                alt=""
                className="h-full w-full object-cover"
                src={imageUrl(color.image, { w: 100, h: 100 })}
              />
            )}
          </Link>
        ))}
      </div>
      {hasMore && (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setShowAll((prev) => !prev)}
          className="mt-2"
        >
          {showAll ? "Show less" : `Show all ${colors.length} colors`}
        </Button>
      )}
    </div>
  );
}
