import { useEffect, useState } from "react";
import { Loader } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import EmptyState from "@/components/ui/empty-state";
import { ProjectOptionPayload, SageSearchProduct } from "@/models";
import ProjectOptionSearchCard from "@/components/projects/ProjectOptionSearchCard";
import SageProductDetails from "@/components/projects/SageProductDetails";
import { useSearchSageProducts } from "@/api/sage";
import { NumberParam, useQueryParam } from "use-query-params";
import { ProductType } from "@/components/projects/ProjectOptionForm";

export default function SageSearchDialog({
  onSelect,
  projectItemId,
}: {
  onSelect: (payload: ProjectOptionPayload, product_type: ProductType) => void;
  projectItemId: number;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const [productId, setProductId] = useQueryParam("prodEid", NumberParam);
  const [query, setQuery] = useState<string>("");

  const { data: sageProducts = [], isLoading } = useSearchSageProducts(query);

  useEffect(() => setProductId(undefined), [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button variant="link" size="xs">
          Search Sage
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-7xl p-0">
        <div className="flex h-[calc(100vh-2rem)] flex-col gap-4 p-6">
          <DialogHeader>
            <DialogTitle>Search Sage for Product</DialogTitle>
          </DialogHeader>
          {!productId ? (
            <div className="grid gap-4 overflow-hidden p-1 lg:grid-cols-4">
              <div>
                <Input
                  type="search"
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="e.g. Yeti Mug"
                />
              </div>
              <div className="col-span-3 overflow-y-auto">
                <div className="flex items-center justify-center">
                  {isLoading && !!query ? (
                    <Loader className="size-10 animate-spin" />
                  ) : sageProducts.length ? (
                    <div className="grid gap-4 lg:grid-cols-3">
                      {sageProducts
                        .slice(0, 100)
                        .map((product: SageSearchProduct) => (
                          <ProjectOptionSearchCard
                            key={product.prodEId}
                            onClick={() => setProductId(product.prodEId)}
                            product={{
                              name: product.name,
                              pic: product.thumbPic,
                              price: product.prc.replace(
                                /\b(\d+(\.\d+)?)\b/g,
                                (p1) => `$${p1}`,
                              ),
                            }}
                          />
                        ))}
                    </div>
                  ) : (
                    <EmptyState
                      title="No Products Found"
                      description="There are no products from Sage that match your search criteria"
                    />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex min-h-0 flex-col items-start gap-2">
              <Button
                variant="link"
                onClick={() => {
                  setProductId(undefined);
                }}
              >
                &larr; Back to Search
              </Button>
              <SageProductDetails
                productId={productId}
                projectItemId={projectItemId}
                onSelect={onSelect}
                setOpen={setOpen}
              />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
