import {
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { OrderApproval } from "@/models";
import { handleLaravelErrors } from "@/lib/form";
import { useQueryClient } from "@tanstack/react-query";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useMaybeUser } from "@/context/AppContext";
import { useNavigate } from "react-router-dom";

const formSchema = z.object({
  name: z.string(),
  email: z.string().email(),
});

export default function ApproveForm({ approval }: { approval: OrderApproval }) {
  const user = useMaybeUser();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: user?.name,
      email: user?.email,
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    return axios
      .post<OrderApproval>(`/approvals/${approval.code}/approve`, values)
      .then(({ data }) => {
        queryClient.invalidateQueries(["approval", approval.code]);

        if (data.project_id) {
          navigate(`/projects/${data.project_id}`);
        }
      })
      .catch(handleLaravelErrors(form));
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogHeader>
          <DialogTitle>Approve Order</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input type="email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <DialogFooter>
          <Button
            type="submit"
            variant="default"
            isLoading={form.formState.isSubmitting}
          >
            Approve
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
