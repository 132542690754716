import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { User } from "@/models";
import { useProject } from "@/context/ProjectContext";
import { useCallback } from "react";

export function useGetProjectUsers() {
  const projectId = useProject().id;
  return useQuery(["project", projectId, "users"], () =>
    axios
      .get<{ data: User[] }>(`projects/${projectId}/users`, {
        params: {
          sort: "name",
        },
      })
      .then(({ data }) => data.data),
  );
}

export function useSetProjectUsers() {
  const project = useProject();
  const queryClient = useQueryClient();

  return useCallback(
    (users: User[]) => {
      queryClient.setQueryData<User[]>(["project", project.id, "users"], users);
      queryClient.invalidateQueries(["project", project.id, "users"]);
    },
    [project.id, queryClient],
  );
}

export function useAddProjectUser() {
  const projectId = useProject().id;
  const setProjectUsers = useSetProjectUsers();
  return useMutation((user: User) =>
    axios
      .post<{ data: User[] }>(`projects/${projectId}/users/${user.id}`)
      .then(({ data }) => {
        setProjectUsers(data.data);
      }),
  );
}

export function useRemoveProjectUser() {
  const projectId = useProject().id;
  const setProjectUsers = useSetProjectUsers();
  return useMutation((user: User) =>
    axios
      .delete<{ data: User[] }>(`projects/${projectId}/users/${user.id}`)
      .then(({ data }) => {
        setProjectUsers(data.data);
      }),
  );
}
