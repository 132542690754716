import { Link, useLocation } from "react-router-dom";
import { Home } from "lucide-react";
import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { useMediaQuery } from "usehooks-ts";

interface Page {
  title: string;
  href: string;
}

export interface BreadcrumbsProps extends HTMLAttributes<HTMLDivElement> {
  crumbs: Page[];
  homeHref?: string;
}

export default function Breadcrumbs({
  className,
  crumbs,
  homeHref = "/",
  ...other
}: BreadcrumbsProps) {
  const { pathname, search } = useLocation();
  const isMobile = useMediaQuery("(max-width: 800px)");

  if (crumbs.length === 0) {
    return null;
  }

  if (!homeHref && crumbs.length === 1) {
    // This hides the breadcrumbs on the shopping page
    // It's unclear what the single breadcrumbs link when there is no home icon or arrow
    return null;
  }

  if (isMobile) {
    let crumb = crumbs[crumbs.length - 2];
    if (!crumb && homeHref) {
      crumb = { title: "Back", href: homeHref };
    }
    if (!crumb) {
      return null;
    }

    return (
      <Link
        to={crumb.href + search}
        className={cn(
          "flex items-center text-sm text-muted-foreground",
          className,
        )}
      >
        <ArrowLeftIcon className="mr-2 size-5" />
        {crumb.title}
      </Link>
    );
  }

  return (
    <nav
      aria-label="Breadcrumb"
      className={cn("flex rounded text-sm text-muted-foreground", className)}
      {...other}
    >
      <ol role="list" className="flex w-full items-center gap-5">
        {homeHref && (
          <li>
            <Link to={homeHref} className="text-muted-foreground">
              <Home className="size-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </li>
        )}
        {crumbs.map((crumb, index) => {
          const isCurrent = pathname === crumb.href.split("?")[0];
          return (
            <li key={crumb.href}>
              <div className="flex items-center">
                {(homeHref || index > 0) && (
                  <span className="size-5 flex-shrink-0" aria-hidden="true">
                    /
                  </span>
                )}
                <Link
                  to={crumb.href + search}
                  aria-current={isCurrent ? "page" : undefined}
                >
                  {crumb.title}
                </Link>
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
