import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import ImageAnchor from "@/components/ui/image-anchor";
import { OrderApprovalLayout } from "@/models";
import { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import { Zoom, Download } from "yet-another-react-lightbox/plugins";
import { DownloadIcon } from "lucide-react";

export default function ApprovalLayoutCard({
  layout,
}: {
  layout: OrderApprovalLayout;
}) {
  const [index, setIndex] = useState(-1);

  return (
    <Card>
      <CardHeader>
        <CardTitle>{layout.name}</CardTitle>
      </CardHeader>
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
        {layout.proof_images.map((img, idx) => (
          <ImageAnchor key={img} href={img} onClick={() => setIndex(idx)}>
            <img src={img} alt="" />
          </ImageAnchor>
        ))}
        {layout.roster_link && (
          <a
            className="flex flex-col items-center justify-center border border-primary px-8 py-8 text-center text-primary"
            href={layout.roster_link}
            download={`roster-${layout.id}.csv`}
          >
            <div className="mb-4">
              <DownloadIcon className="size-12" />
            </div>
            <div className="mb-1 px-2 text-2xl font-medium">Roster</div>
            <div className="text-slate-600">
              Please download and inspect this list of names/numbers for
              spelling and accuracy.
            </div>
          </a>
        )}
      </div>

      <Lightbox
        open={index > -1}
        close={() => setIndex(-1)}
        index={index}
        slides={layout.proof_images.map((img) => ({ src: img }))}
        plugins={[Zoom, Download]}
      />
    </Card>
  );
}
