import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { curr } from "@/lib/curr";
import { OrderItem } from "@/models";

export default function OrderItemsTable({
  items,
}: {
  items: Pick<
    OrderItem,
    | "id"
    | "description"
    | "color"
    | "size"
    | "discount"
    | "price"
    | "qty"
    | "subtotal"
  >[];
}) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Description</TableHead>
          <TableHead>Color</TableHead>
          <TableHead>Size</TableHead>
          <TableHead>Price</TableHead>
          <TableHead>Qty</TableHead>
          <TableHead>Line Total</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.id}>
            <TableCell>{item.description}</TableCell>
            <TableCell>{item.color}</TableCell>
            <TableCell>{item.size}</TableCell>
            <TableCell>
              {item.discount ? (
                <div className="space-x-2">
                  <span className="text-muted-foreground line-through">
                    {curr(item.price)}
                  </span>
                  <span>{curr(item.subtotal / item.qty)}</span>
                </div>
              ) : (
                curr(item.price)
              )}
            </TableCell>
            <TableCell>{item.qty}</TableCell>
            <TableCell>{curr(item.subtotal)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
