import { Tags } from "lucide-react";
import { Collection } from "@/models";
import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils";

export default function CollectionCard({
  collection,
  className,
}: {
  collection: Collection;
  className?: string;
}) {
  return (
    <Card
      className={cn(
        "relative flex flex-col items-center py-2 hover:shadow-md",
        className,
      )}
    >
      <div className="mb-4 flex h-40 w-32 max-w-full flex-col items-center justify-center">
        {collection.image ? (
          <img
            src={collection.image}
            alt=""
            className="h-full w-full object-cover"
          />
        ) : (
          <Tags className="size-10" />
        )}
      </div>
      <div className="py-2 text-center font-medium">{collection.name}</div>
    </Card>
  );
}
