import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { BrandColor, BrandFile } from "@/models";

export function useGetBrandFiles() {
  return useQuery(
    ["brandFiles"],
    () =>
      axios
        .get<{ data: BrandFile[] }>("brand-files")
        .then(({ data }) => data.data),
    {
      staleTime: 1000 * 60 * 5,
    },
  );
}

export interface BrandFilePayload {
  name: string;
  file: string;
}

export function useCreateBrandFile(type: "logo" | "font") {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: BrandFilePayload) =>
      axios
        .post<BrandFile>("brand-files", { ...payload, type })
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<BrandFile[]>(["brandFiles"], (prev) => {
          if (!prev) return prev;
          return [...prev, data];
        });
      },
    },
  );
}

export function useUpdateBrandFile(file: BrandFile) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: Partial<BrandFilePayload>) =>
      axios
        .put<BrandFile>(`brand-files/${file.id}`, payload)
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<BrandFile[]>(["brandFiles"], (prev) => {
          if (!prev) return prev;
          return prev.map((file) => {
            if (file.id === data.id) {
              return data;
            }
            return file;
          });
        });
      },
    },
  );
}

export function useDeleteBrandFile() {
  const queryClient = useQueryClient();

  return useMutation((id: number) => axios.delete(`brand-files/${id}`), {
    onSuccess: (_, id) => {
      queryClient.setQueryData<BrandFile[]>(["brandFiles"], (prev) => {
        if (!prev) return prev;
        return prev.filter((file) => file.id !== id);
      });
    },
  });
}

export function useGetBrandColors() {
  return useQuery(["brandColors"], () =>
    axios
      .get<{ data: BrandColor[] }>("brand-colors")
      .then(({ data }) => data.data),
  );
}

export interface BrandColorPayload {
  hex: string;
}

export function useCreateBrandColor() {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: BrandColorPayload) =>
      axios.post<BrandColor>("brand-colors", payload).then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<BrandColor[]>(["brandColors"], (prev) => {
          if (!prev) return prev;
          return [...prev, data];
        });
      },
    },
  );
}

export function useUpdateBrandColor(color: BrandColor) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: BrandColorPayload) =>
      axios
        .put<BrandColor>(`brand-colors/${color.id}`, payload)
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<BrandColor[]>(["brandColors"], (prev) => {
          if (!prev) return prev;
          return prev.map((color) => {
            if (color.id === data.id) {
              return data;
            }
            return color;
          });
        });
      },
    },
  );
}

export function useDeleteBrandColor() {
  const queryClient = useQueryClient();

  return useMutation((id: number) => axios.delete(`brand-colors/${id}`), {
    onSuccess: (_, id) => {
      queryClient.setQueryData<BrandColor[]>(["brandColors"], (prev) => {
        if (!prev) return prev;
        return prev.filter((color) => color.id !== id);
      });
    },
  });
}
