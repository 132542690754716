import { Collection } from "@/models";
import CollectionCard from "@/components/collections/CollectionCard";
import { Link } from "react-router-dom";

export default function CollectionLink({
  collection,
  to,
}: {
  collection: Collection;
  to: string;
}) {
  return (
    <Link to={to}>
      <CollectionCard collection={collection} />
    </Link>
  );
}
