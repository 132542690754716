import LoginForm from "@/components/auth/LoginForm";
import AuthLayout from "@/layouts/AuthLayout";
import { Link, useSearchParams } from "react-router-dom";
import SocialLogins from "@/components/auth/SocialLogins";
import { getUrlWithExistingSearch } from "@/lib/auth";
import { useTitle } from "@/lib/title";
import { useAppContext } from "@/context/AppContext";
import AlreadyLoggedInRedirect from "@/components/auth/AlreadyLoggedInRedirect";
import { Alert } from "@/components/ui/alert";
import OrDivider from "@/components/ui/or-divider";

export default function Login() {
  useTitle("Login");

  const context = useAppContext();
  const [searchParams] = useSearchParams();

  if (context.user) {
    return <AlreadyLoggedInRedirect data={context} />;
  }

  return (
    <AuthLayout
      title="Log in to your account"
      description={
        <>
          <span>Don't have an account? </span>
          <Link
            to={getUrlWithExistingSearch("/register")}
            className="font-medium text-primary"
          >
            Create an account now
          </Link>
        </>
      }
    >
      {searchParams.get("redirect_to") === "/projects/submit" && (
        <Alert variant="info" className="-mt-4 mb-4">
          Please log in to submit your project.
        </Alert>
      )}

      <SocialLogins verb="Log in" />
      <OrDivider />
      <LoginForm />
    </AuthLayout>
  );
}
