import { createContext, useContext } from "react";
import { DesignLayout, Product } from "@/models";

export type ShopState = {
  product?: Product;
  variants?: Record<number, number>;
  designLayout?: DesignLayout;
  skipDecoration?: boolean;
};

const ShopContext = createContext<
  ShopState &
    (
      | {
          updateShopState: (state: Partial<ShopState>) => void;
          resetState: () => void;
        }
      | {
          updateShopState: undefined;
          resetState: undefined;
        }
    )
>({
  updateShopState: undefined,
  resetState: undefined,
});

export default ShopContext;

export function useShopContext() {
  return useContext(ShopContext);
}

export function useShopState() {
  const context = useContext(ShopContext);
  if (!context.updateShopState) {
    throw new Error("ShopContext is not initialized");
  }
  return context;
}

export function useIsShopping() {
  const { updateShopState } = useContext(ShopContext);
  return !!updateShopState;
}
