import { FulfillmentOrder } from "@/models";
import { Badge, BadgeProps } from "@/components/ui/badge";
import startCase from "lodash/startCase";

export function getShipmentStatusVariant(
  status: FulfillmentOrder["status"],
): BadgeProps["variant"] {
  switch (status) {
    case "pending":
    case "issued":
      return "outline";
    case "waiting":
      return "pink";
    case "processing":
      return "teal";
    case "shipped":
      return "green";
    case "voided":
      return "red";
    case "backordered":
      return "indigo";
  }
}

export default function FulfillmentOrderStatus(
  props: Pick<FulfillmentOrder, "status">,
) {
  return (
    <Badge variant={getShipmentStatusVariant(props.status)}>
      {startCase(props.status)}
    </Badge>
  );
}
