import { Navigate } from "react-router-dom";
import Lottie from "lottie-react";
import loadingJson from "@/assets/lottie/loading.json";
import { useEffect } from "react";
import { CreateProjectPayload, useCreateProject } from "@/api/project";
import { Alert } from "@/components/ui/alert";
import { CREATE_PROJECT_STORAGE_KEY } from "@/constants/project";

export default function ProjectSubmit() {
  const createProjectRequest = useCreateProject();
  const payload = localStorage.getItem(CREATE_PROJECT_STORAGE_KEY);

  useEffect(() => {
    if (payload) {
      createProjectRequest
        .mutateAsync(JSON.parse(payload) as CreateProjectPayload)
        .then(() => {
          localStorage.removeItem(CREATE_PROJECT_STORAGE_KEY);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!payload) {
    const url = new URLSearchParams(window.location.search);
    const onErrorUrl = url.get("redirect_to") || "/projects/create";
    return <Navigate to={onErrorUrl} />;
  }

  if (createProjectRequest.isError) {
    return <Alert>There was an error submitting your project.</Alert>;
  }

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <Lottie animationData={loadingJson} loop />
    </div>
  );
}
