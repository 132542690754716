import * as z from "zod";
import { DECORATION_METHODS } from "@/constants/project";

export interface User {
  id: number;
  name: string;
  email: string;
  email_verified_at: string | null;
  avatar: string | null;
  created_at: string;
}

export interface Customer {
  id: number;
  name: string;
  logo: string | null;
  website: string | null;
}

export interface OrderType {
  id: number;
  name: string;
}

export interface Business {
  id: number;
  name: string;
  slug: string;
  logo: string | null;
  icon: string | null;
  url: string | null;
  is_house: boolean;
}

export interface Webstore {
  id: number;
  name: string;
  type: "fulfillment" | "ondemand" | "popup" | "quick" | "demo";
  logo: string;
  slug: string;
  url: string;
  revenue: number;
  fulfillment_fees: number;
  sales_tax: number;
  rebate: number;
  status: "live" | "imported" | "archived" | "draft";
  orders: {
    id: number;
    increment_id: string;
    description: string;
    invoiced_at: string;
    created_at: string;
    revenue: number;
    fulfillment_fees: number;
    sales_tax: number;
    rebate: number;
  }[];
}

export interface OrderItem {
  id: number;
  description: string;
  color: string | null;
  size: string | null;
  qty: number;
  price: number;
  subtotal: number;
  discount: number | null;
  created_at: string;
}

export interface Address {
  id: number;
  method: string | null;
  name: string;
  company?: string | null;
  address_1: string;
  address_2?: string | null;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone?: string;
  third_party_account: string | null;
  require_signature: boolean;
  service_level: string | null;
  addressable_id: number;
  addressable_type: string;
}

export const ZodAddress = z.object({
  name: z.string(),
  company: z.string().nullish(),
  address_1: z.string(),
  address_2: z.string().nullish(),
  city: z.string(),
  state: z.string().nullish(),
  zip: z.string(),
  country: z.string().default("US"),
  third_party_account: z.string().nullish(),
  method: z.string().nullish(),
  save_address: z.boolean().optional(),
  is_billing: z.boolean().optional(),
  require_signature: z.boolean().optional(),
  is_residential: z.boolean().nullish(),
});

export type AddressPayload = z.infer<typeof ZodAddress>;

export interface Order {
  id: number;
  business: Business;
  code: string;
  placed_at: string;
  increment_id: string;
  description: string;
  order_type: OrderType;
  total: number;
  subtotal: number;
  shipping: number;
  precollected_tax: number;
  tax: number;
  total_tax: number;
  status:
    | "Complete"
    | "Order Received"
    | "Waiting on Payment"
    | "Waiting on PO"
    | "Ordered from Vendor"
    | "In Production"
    | "Pending Shipment"
    | "Approved"
    | "Cancelled";
  inbound_freight: number;
  paid: number;
  balance: number;
  order_approved_at: string | null;
  invoiced_at: string | null;
  due_date: string | null;
  shipping_address: Address | null;
  billing_address: Address | null;
  customer_po: string | null;
  terms: {
    name: string;
    is_po_required: boolean;
    is_upfront: boolean;
  };
  contact_name: string | null;
  contact_email: string | null;
  contact_phone: string | null;
  outstanding_approvals?: OrderApproval[];
  waiting_on_payment: boolean;
  waiting_on_po: boolean;
  has_production?: boolean;
}

export interface Quote {
  id: number;
  business: Business;
  code: string;
  placed_at: string;
  increment_id: string;
  description: string;
  order_type: OrderType;
  total: number;
  subtotal: number;
  shipping: number;
  tax: number;
  status: "open" | "accepted" | "declined" | "ordered";
  inbound_freight: number;
  shipping_address: Address | null;
  billing_address: Address | null;
  contact_name: string | null;
  contact_email: string | null;
  contact_phone: string | null;
}

export interface Invoice extends Order {
  invoiced_at: string;
  due_date: string;
}

export interface Shipment {
  id: number;
  tracking_url: string | null;
  tracking_number: string | null;
  created_at: string;
  carrier: string | null;
  carrier_name: string | null;
  shippable?: Order | FulfillmentOrder | null;
}

export interface PoStatus {
  id: number;
  factory_order_number: string;
  additional_explanation: string | null;
  status: string;
  status_id: 20 | 75 | 80;
  expected_ship_date: string | null;
  last_updated_at: string;
}

export interface Proof {
  id: number;
  type: "customer" | "production";
  image: string;
  pdf: string | null;
  file_name: string;
  created_at: string;
}

export interface DesignLayout {
  id: number;
  image: string | null;
  pdf: string | null;
  order: Order;
  increment_id: string;
  name: string;
  created_at: string;
}

export type MembershipRole =
  | "avail"
  | "avail_incognito"
  | "admin"
  | "member"
  | "guest";

interface BaseMembership {
  id: number;
  role: MembershipRole;
  unread_notification_count?: number;
  created_at: string;
}

export interface UserMembership extends BaseMembership {
  customer: Customer;
}

export interface CustomerMembership extends BaseMembership {
  user: User;
}

export interface Invite {
  id: number;
  role: string;
  email: string;
  created_at: string;
}

export interface OrderApprovalLayout {
  id: number;
  name: string;
  proof_images: string[];
  roster_link: string | null;
}

export interface SealedOrder {
  increment_id: string;
  description: string;
  items: {
    id: number;
    description: string;
    number: string;
    color: string;
    size: string;
    qty: number;
    price: number;
    discount: number | null;
    subtotal: number;
  }[];
  layouts: OrderApprovalLayout[];
  shipping_address: Address;
  billing_address: Address;
  kit_addresses: number | null;
  contact: {
    first_name: string | null;
    last_name: string | null;
    email: string | null;
    phone: string | null;
  };
  memo: string | null;
  subtotal: number;
  shipping: number;
  inbound_freight: number;
  tax: number;
  total: number;
  terms: string;
  customer_po: string | null;
}

export interface OrderApproval {
  id: number;
  business: Business;
  code: string;
  status: "Approved" | "Declined" | "Pending";
  label: string;
  is_order: boolean;
  is_art: boolean;
  business_name: string;
  created_at: string;
  is_sealed: boolean;
  sealed_html: string | null;
  note: string | null;
  decider_name: string | null;
  decider_email: string | null;
  approved_at: string | null;
  rejected_at: string | null;
  rejection_note: string | null;
  order_id: number;
  order_code: string;
  waiting_on_payment: boolean;
  waiting_on_po: boolean;
  order: SealedOrder;
  project_id: number | null;
}

export interface Sku {
  id: number;
  image: string;
  sku: string;
  description: string;
  inventory_qty: number;
  inventory_scrapped: number;
  inventory_on_order: number;
  sold_qty: number;
  created_at: string;
  par_level: number | null;
  alert_level: number | null;
  value: number | null;
  average_purchase_price: number | null;
  calculated_value: number | null;
  size: string | null;
  weight: number | null;
  upc: string | null;
  gtin: string | null;
}

export type SkuQty = Sku & { qty: number };

export interface InventoryEntry {
  id: number;
  description: string;
  qty: number;
  balance: number;
  created_at: string;
}

export interface FulfillmentOrderItem {
  id: number;
  variant_id: number;
  sku: string;
  description: string;
  qty: number;
  notes: string | null;
}

export interface FulfillmentOrder {
  id: number;
  date: string;
  number: string;
  name: string;
  email: string;
  phone: string | null;
  status:
    | "pending"
    | "waiting"
    | "issued"
    | "processing"
    | "shipped"
    | "voided"
    | "backordered";
  earliest_ship_date: string | null;
  shipments: Shipment[];
  items: FulfillmentOrderItem[];
  address: Address;
  user?: User | null;
}

export interface Country {
  id: string;
  name: string;
  states: {
    code: string;
    name: string;
  }[];
}

export interface Brand {
  id: number;
  name: string;
}

export interface ProductImage {
  id: number;
  url: string;
}

export interface ProductVariant {
  id: number;
  sku: string;
  size: string;
  price: number;
  cost?: number; // Only if Avail role
  vendor_inventory_qty: number | null;
  stock_updated_at: string | null;
}

type DecorationMethod =
  | "screenprint"
  | "embroidery"
  | "ad_specialty"
  | "heat_transfer"
  | "blanks";

export type ComplexityUnit = "colors" | "stitches" | "other";

export interface ProductDecoration {
  location: string;
  decoration: string;
  decoration_unit: ComplexityUnit;
  decoration_units_min: number | null;
  decoration_units_max: number | null;
  decoration_units_included: number | null;
}

export const ZodDecorationConfig = z.object({
  location: z.string().nullish(),
  decoration: z.string().nullish(),
  complexity: z.coerce.number().nullish(),
});

export type DecorationConfig = z.infer<typeof ZodDecorationConfig>;

export interface Product {
  id: number;
  slug: string;
  name: string;
  style_number: string;
  color: string | null;
  color_code: string | null;
  color_hex: string | null;
  swatch_image: string | null;
  description: string | null;
  image: string | null;
  brand: Brand | null;
  classification: string;
  decoration_method: DecorationMethod;
  price: number;
  min_price: number;
  min_qty: number | null;
  vendor: string;
  variants: ProductVariant[];
  images: ProductImage[];
  decorations?: ProductDecoration[];
}

export interface CartItem {
  id: number;
  product: Product;
  collection_id: number | null;
  design_layout: DesignLayout | null;
  decoration: DecorationConfig | null;
  variants: {
    variant_id: number;
    qty: number;
  }[];
  subtotal: number;
  qty: number;
  created_at: string;
}

export interface Cart {
  id: number;
  items: CartItem[];
  orders: OrderPreviewModel[];
  subtotal: number;
  created_at: string;
  updated_at: string;
}

/* This should match OrderCreatePayload data class from API */
export interface OrderPreviewModel {
  id: number;
  items: {
    description: string;
    size: string;
    price: number;
    qty: number;
    fee?: boolean;
  }[];
  total_qty: number;
  subtotal: number;
  shipping: number;
  inbound_freight: number | null;
}

export interface Collection {
  id: number;
  slug: string;
  name: string;
  description: string | null;
  image: string | null;
  children?: Collection[];
  products_count?: number;
  products?: Product[];
}

export interface ProjectItem {
  id: number;
  collection: Collection;
  product?: Product | null;
  notes: string | null;
}

export interface ProjectField {
  field: CustomerProjectField;
  value: string | null;
}

export type ProjectType = "project" | "self-service" | "webstore";

export interface Project {
  id: number;
  type: ProjectType;
  name: string;
  status:
    | "draft"
    | "options_requested"
    | "options_provided"
    | "proofs_requested"
    | "ordered"
    | "complete"
    | "cancelled";
  budget_type: "per_person" | "total";
  budget_qty: number;
  budget_amount: number;
  description: string;
  in_hands_date: string;
  shipping_mode: ShippingMode;
  orders: Order[];
  items: ProjectItem[];
  address: Address | null;
  attachments: Attachment[];
  fields: ProjectField[];
  created_at: string;
  user: User | null;
  total: number | null;
  balance: number | null;
  due_date: string | null;
  customer_po: string | null;
}

export interface ProjectOptionVariant {
  size: string;
  price: number;
  cost: number;
  variant_id?: number;
  qty?: number | null;
}

export const ZodProjectOptionTags = z
  .object({
    made_in_usa: z.boolean(),
    sustainable: z.boolean(),
    diverse_supplier: z.boolean(),
  })
  .partial();

export const ZodProjectOptionTag = ZodProjectOptionTags.keyof();

export const ZodLayoutConfig = z.object({
  id: z.number().nullish(),
  name: z.string().nullish(),
  locations: z.array(ZodDecorationConfig).nullish(),
});

export type LayoutConfig = z.infer<typeof ZodLayoutConfig>;

export const ZodProjectOption = z
  .object({
    sample_order_id: z.number().nullable().default(null),
    project_item_id: z.number(),
    name: z.string().min(1),
    nickname: z.string().nullish(),
    type: z.enum(DECORATION_METHODS),
    description: z.string().min(1),
    images: z.array(z.string().url()),
    layout_config: ZodLayoutConfig.nullish(),
    min_qty: z.coerce.number(),
    estimated_qty: z.coerce.number().nullish(),
    use_dynamic_pricing: z.boolean(),
    is_pinned: z.boolean().nullish(),
    enable_color_options: z.boolean(),
    target_margin: z.number().min(0).max(100),
    fees: z.array(
      z.object({
        name: z.string().min(1),
        price: z.coerce.number(),
        cost: z.coerce.number().nullish(),
        qty: z.coerce.number().nullish(),
      }),
    ),
    tags: ZodProjectOptionTags,
  })
  .and(
    z.discriminatedUnion("product_type", [
      z.object({
        product_type: z.literal("custom"),
        number: z.string().min(1),
        color: z.string().min(1),
        vendor_id: z.number(),
        variants: z.array(
          z.object({
            size: z.string().min(1),
            price: z.coerce.number(),
            variant_id: z.undefined().nullish(),
            cost: z.coerce.number(),
          }),
        ),
      }),
      z.object({
        product_type: z.literal("database"),
        product_id: z.number(),
        variants: z.array(
          z.object({
            size: z.string().min(1),
            price: z.coerce.number(),
            variant_id: z.number(),
            cost: z.number().nullish(),
          }),
        ),
      }),
    ]),
  );

export type ProjectOptionTag = z.infer<typeof ZodProjectOptionTag>;
export type ProjectOptionPayload = z.infer<typeof ZodProjectOption>;

export interface ProjectOption {
  id: number;
  color_options: Product[];
  project_item_id: number;
  name: string;
  nickname: string | null;
  type: DecorationMethod;
  description: string;
  images: string[];
  min_qty: number;
  estimated_qty: number | null;
  number: string;
  price: number;
  price_qty: number;
  color: string;
  product: Product | null;
  vendor: Vendor | null;
  variants: ProjectOptionVariant[];
  fees: {
    name: string;
    price: number;
    cost?: number;
    qty?: number;
  }[];
  order_id: number | null;
  layout_config: LayoutConfig | null;
  use_dynamic_pricing: boolean;
  target_margin: number;
  enable_color_options: boolean;
  tags: Record<ProjectOptionTag, boolean> | null;
  sample_order: Order | null;
  is_pinned: boolean;
}

export interface ProjectSelection {
  id: number;
  quantities: number[];
  option: ProjectOption;
  subtotal: number;
  total_qty: number;
  fixed_subtotal: number;
  variable_subtotal: number;
}

export interface Attachment {
  name: string;
  file: string;
  url: string;
}

export interface ProjectActivityReaction {
  id: number;
  user: User;
  reaction: string;
  created_at: string;
}

interface BaseProjectActivity {
  id: number;
  description: string;
  user: User;
  created_at: string;
  reactions: ProjectActivityReaction[];
  note: string | null;
}

export interface ProjectMessage {
  message: string;
  attachments: Attachment[];
}

type ActivityTypes =
  | {
      type: "created";
      eventable: null;
    }
  | {
      type: "messaged";
      eventable: ProjectMessage;
    }
  | {
      type: "added_user" | "removed_user";
      eventable: User;
    }
  | {
      type: "approved_order" | "rejected_order";
      eventable: Order;
    }
  | {
      type: "sample_shipped";
      eventable: Shipment;
    }
  | {
      type: "sample_delivered";
      eventable: Shipment;
    };

export type ProjectActivity = BaseProjectActivity & ActivityTypes;

export interface Vendor {
  id: number;
  name: string;
}

export interface Notification {
  id: number;
  read_at: string | null;
  details: {
    user: string;
    action: string;
    label: string;
    href: string;
    avatar: string | null;
  };
  created_at: string;
}

export interface CustomerProjectField {
  id: number;
  label: string;
  type: "text" | "email" | "select" | "date" | "number";
  options: string[] | null;
  help: string | null;
  required: boolean;
}

export type ShippingMode = "bulk" | "kits" | "inventory";

export interface SageSearchProduct {
  name: string;
  prc: string;
  prodEId: number;
  spx: string;
  thumbPic: string;
}

interface OptionValue {
  Value: string;
  Prc: string[];
  Net: string[];
}

interface Option {
  Name: string;
  Values: OptionValue[];
  PricingIsTotal: number;
  PriceCode: string;
}

export interface Picture {
  index: number;
  caption: string;
  url: string;
  hasLogo: number;
}

interface SupplierInfo {
  suppId: number;
  coName: string;
  lineName: string;
  contactName: string;
  mAddr: string;
  mCity: string;
  mState: string;
  mZip: string;
  mCountry: string;
  sAddr: string;
  sCity: string;
  sState: string;
  sZip: string;
  sCountry: string;
  tel: string;
  tollFreeTel: string;
  fax: string;
  tollFreeFax: string;
  email: string;
  web: string;
  unionShop: number;
  esg: string;
  artContactName: string;
  artContactEmail: string;
  catYear: string;
  catExpOn: string;
  catCurrency: string;
  comment: string;
  prefGroupIDs: string;
  prefGroups: string;
  persCsRep: string;
  persCsRepPhn: string;
  persCsRepEmail: string;
  persCustNum: string;
  persSuppNote: string;
  generalInfo: {
    artInfo: string;
    copyChangeInfo: string;
    imprintMethods: string;
    imprintColors: string;
    proofInfo: string;
    pMsCharge: string;
    pMsChargeCode: string;
    copyChangeCharge: string;
    copyChangeChargeCode: string;
    artChargeHr: string;
    artChargeHrCode: string;
    artChargeJob: string;
    artChargeJobCode: string;
    proofCharge: string;
    proofChargeCode: string;
    specSampleCharge: string;
    specSampleChargeCode: string;
    orderChangeInfo: string;
    orderCancelInfo: string;
    lessMinInfo: string;
    overrunInfo: string;
    shipInfo: string;
    termsInfo: string;
    warrantyInfo: string;
    returnsInfo: string;
    coOpInfo: string;
    otherInfo: string;
  };
}

export interface SageProductDetail {
  prodEid: number;
  category: string;
  suppId: number;
  lineName: string;
  catPage: string;
  catYear: number;
  itemNum: string;
  spc: string;
  prName: string;
  description: string | null;
  dimensions: string;
  keywords: string;
  colors: string;
  themes: string;
  qty: string[];
  prc: string[];
  priceCode: string;
  catPrc: string[];
  catPriceCode: string;
  net: string[];
  priceAdjustMsg: string;
  currency: string;
  piecesPerUnit: string[];
  options: Option[];
  madeInCountry: string;
  assembledInCountry: string;
  decoratedInCountry: string;
  recyclable: number;
  newProduct: number;
  envFriendly: number;
  audienceWarning: number;
  food: number;
  clothing: number;
  productCompliance: string;
  warningLbl: string;
  productComplianceMemo: string;
  verified: number;
  imprintArea: string;
  imprintLoc: string;
  secondImprintArea: string;
  secondImprintLoc: string;
  decorationMethod: string;
  decorationNotOffered: number;
  setupChg: string;
  setupChgCode: string;
  repeatSetupChg: string;
  repeatSetupChgCode: string;
  screenChg: string;
  screenChgCode: string;
  plateChg: string;
  plateChgCode: string;
  dieChg: string;
  dieChgCode: string;
  toolingChg: string;
  toolingChgCode: string;
  addClrChg: string;
  addClrChgCode: string;
  addClrRunChg: string[];
  addClrRunChgCode: string;
  priceIncludes: string;
  package: string;
  weightPerCarton: string;
  unitsPerCarton: string;
  cartonL: string;
  cartonW: string;
  cartonH: string;
  shipPointCountry: string;
  shipPointZip: string;
  prodTime: string;
  comment: string;
  pics: Picture[];
  expDate: string;
  discontinued: number;
  active: number;
  supplier: SupplierInfo;
}

export interface BrandFile {
  id: number;
  name: string;
  type: string;
  file: string;
  image: string | null;
  colors: string[] | null;
  created_at: string;
}

export interface BrandColor {
  id: number;
  cmyk: string | null;
  hex: string | null;
  rgb: string | null;
  ink_color: string | null;
  thread_color: string | null;
}
